import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const BucketTabs = (props) => {
  const handleActiveButton = (tab) => {
    props.handleTabChange(tab);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #cccccc",
      }}
    >
      <div style={{ display: "flex", justifyContent: "start", marginLeft: 10 }}>
        {/* {props.activeTab === 'leads' || props.activeTab === 'registered' ||  props.activeTab === 'chatList' ? ( */}
        <div>
          {props.currentParentTabStatus!=="invoice_created" && 
          <button
            style={{
              ...styles.wiredButton,
              borderBottomWidth: props.activeTab === "standard" ? "2px" : "0px",
              color: props.activeTab === "standard" ? "#6147FF" : "#666666",
              fontWeight: props.activeTab === "standard" ? "bold" : "normal",
            }}
            onClick={() => handleActiveButton("standard")}
          >
            Standard
          </button>}
          <button
            style={{
              ...styles.wiredButton,
              borderBottomWidth: props.activeTab === "cndn" ? "2px" : "0px",
              color: props.activeTab === "cndn" ? "#6147FF" : "#666666",
              fontWeight: props.activeTab === "cndn" ? "bold" : "normal",
            }}
            onClick={() => handleActiveButton("cndn")}
          >
            Credit/Debit Notes
          </button>
        </div>
      </div>
      {props.currentParentTabStatus=="pending" && <div style={{ display: "flex", justifyContent: "end" }}>
        <div>
          <button
            style={styles.addPartnerButton}
            onClick={(e) => props.setPopup(true)}
            className="submit"
          >
            Add Notes
          </button>
        </div>
      </div>
      }
    </div>
  );
};

const styles = {
  addPartnerButton: {
    backgroundColor: "#6147FF",
    color: "#fff",
    borderRadius: "10px 10px 0 0",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "center",
  },
  wiredButton: {
    backgroundColor: "transparent",
    color: "#6147FF",
    borderTop: "0px",
    borderLeft: "0px",
    borderRight: "0px",
    borderBottom: "2px solid #6147FF",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    marginRight: "10px",
  },
};
export default BucketTabs;
