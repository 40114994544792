import React, { useEffect, useMemo, useState } from "react";
import Formsy from "formsy-react";
import FormsyInputField from "../../../elements/FormsyInputField";
import FormsyDatePicker from "../../../elements/FormsyDatePicker";
import FormsySelect from "../../../elements/FormsySelect";
import FormFields from "../../../leads/formFields/bankPOCFormFields.json";
import { MasterService } from "../../../../services";
import axios from "axios";
import MultiSelect from "../../../elements/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { getMasterCityState, setCityList } from "../../../../store/action/allAction";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../../elements/Loader";

const DisbursedDetailsForm = FormFields["Partner_BusinessDetails_Page"];
const PartnerBankLoans = FormFields["Partner_Bank_Loans"]
const DisbursedCases = FormFields["Disbursed_Cases"]
const YearMap = FormFields["Year_Map"]

const PartnerBusinessDetailsPage = (leadInfo) => {
  const [disburseDetails, setDisbursedDetails] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [walletDetails, setWalletDetails] = useState({});
  const [loanplatforms, setLoanplatforms] = useState({
    loan_platforms: ""
  });
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));
  const [otherProducts, setOtherProducts] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleRadioChange = (event) => {
    setAllowSubmit(true)
    const { name, id, checked } = event.target;
    let value = "";
    if (checked && id === "yes") {
      value = "1";
    } else if (id === "no") {
      value = "0";
    }
    setDisbursedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleMultiCheckbox = (event ) => {
    setAllowSubmit(true)
     const { value } = event?.target;
    setLoanplatforms((prevDetails) => {
      const isChecked = prevDetails?.loan_platforms?.includes(value);
      return {
        ...prevDetails,
        loan_platforms: isChecked
          ? prevDetails?.loan_platforms?.filter((item) => item !== value)
          : [...prevDetails.loan_platforms, value],
      };
    });
  };

  const handleMultiSelect = (
    fieldName,
    selectedOptArr,
    isMultiSel,
    OptVal,
    OptLabel
  ) => {
    setAllowSubmit(true)
    if(fieldName === "pref_bankers"){
    const selectedBanks = selectedOptArr?.map((obj) => obj[OptVal]);
    setDisbursedDetails((current) => ({
      ...current,
      pref_bankers: selectedBanks,
    }));
    }else if(fieldName === "other_products"){
    const selectedProducts = selectedOptArr?.map((obj) => obj[OptVal]);
    setDisbursedDetails((current) => ({
      ...current,
      other_products: selectedProducts,
    }));
    }
  };

  const handleDateChange = (event) => {
    setAllowSubmit(true)
    const selectedDate = event?.target?.value || event; 
    setDisbursedDetails((prevDetails) => ({
        ...prevDetails,
        next_case_expected_date: moment(selectedDate)?.format("YYYY-MM-DD"),
    }));
};

useEffect(() => {
  if (!masterdata?.data || !masterdata?.data?.state_list) {
    dispatch(getMasterCityState());
  }
}, []);


const handleSelectChange = (data, field_name) => {
  setAllowSubmit(true)
  if (data && field_name) {
    if (field_name.includes("state")) {
      let options = masterdata ? { ...masterdata?.data } : {};
      if (options?.city && options?.city?.length) {
        let record = options.city
          .filter((ob) => ob?.state_id == data?.value)
          .map((data) => {
            data.value = data?.id;
            data.label = data?.label;
            return data;
          });
          dispatch(setCityList(record));

      }
    }
    setDisbursedDetails((currentValue) => ({
      ...currentValue,
      [field_name]: data?.value,
    }));
  }
};

  const handleInputChange = (event, fieldName) => {
    setAllowSubmit(true)
    const { value } = event?.target; 
    setDisbursedDetails((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
 

  const Payouts_TABS = [
    { id: "3", label: "0-7 Days", value: "3" },
    { id: "5", label: "7-10 Days", value: "5" },
    { id: "7", label: "10-15 Days", value: "7" },
    { id: "10", label: "15-20 Days", value: "10" },
    { id: "13", label: "20-30 Days", value: "13" },
    { id: "16", label: "30-45 Days", value: "16" },
    { id: "19", label: "45-60 Days", value: "19" },
    { id: "22", label: "60+Days", value: "22" },
    { id: "-1", label: "None", value: "-1" },
  ];

  const handleTabChange = (value) => {
    setAllowSubmit(true)
    setDisbursedDetails((prevState) => ({
      ...prevState,
       disbursement_duration: value,
    }));
  };
  
  const fetchWalletList = async (leadInfo) => {
    setIsLoading(true);
    try {
      const response = await MasterService.get(
        `/partner/partner/get-wallet-info?partner_id=${leadInfo?.id}&user_id=${leadInfo?.user_id}&source=PST`
      );
  
      if (response && response?.data?.status === 200) {
        setWalletDetails(response.data);
  
        try {
          const business_data = JSON.parse(response?.data?.data?.business_data || '{}');
  
          let business_details = {
            home_loan_cases: response?.data?.data?.home_loan_cases ,
            loan_ticket_size: response?.data?.data?.loan_ticket_size ,
            other_products: (response?.data?.data?.other_products? JSON.parse(response?.data?.data?.other_products) : ""), 
            other_products_details: response?.data?.data?.other_products_details ,
            cra_city: business_data?.cra_city ,
            cra_state: business_data?.cra_state ,
            specific_area: business_data?.specific_area,
            team_size: response?.data?.data?.team_size ,
            office_status: response?.data?.data?.office_status ,
            pref_bankers: business_data?.pref_bankers ,
            next_case_expected_date: business_data?.next_case_expected_date ,
            disbursement_duration: business_data?.disbursement_duration ,
            min_payout_per_disbursed: business_data?.min_payout_per_disbursed ,
            max_payout_per_disbursed: business_data?.max_payout_per_disbursed ,
            other_platforms: business_data?.other_platforms ,
          };
  
          setLoanplatforms({ loan_platforms: business_data?.loan_platforms||""});
          setDisbursedDetails(business_details);
          setIsLoading(false);
        } catch (error) {
          toast.error("Error parsing business_data:", error);
        }
      } else {
        toast.error("Failed to fetch wallet info. Status:", response?.status);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Error fetching wallet info:", error);
    }
  };

  const fetchOtherProducts= async () => {
    try {
      const response = await MasterService.get(
        `/partner/partner/other-products-list`
      );
  
      if (response && response.status === 200) {
       setOtherProducts(response.data.data);
      } else {
        toast.error("Failed to fetch other products. Status:", response?.status);
      }                     

    } catch (error) {
      toast.error("Error fetching wallet info:", error);
    }
  }
  
  useEffect(()=>{
    fetchWalletList(leadInfo?.leadInfo);
    fetchOtherProducts();
  },[])
  
  useEffect(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    if (options?.city && options?.city?.length) {
      let record = options.city
        .filter((ob) => ob?.state_id == disburseDetails?.cra_state)
        .map((data) => {
          data.value = data?.id;
          data.label = data?.label;
          return data;
        });
    }
  }, [disburseDetails?.cra_state]);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata?.data } : {};  
    return options;
  }, [masterdata]);


  const updatedDisbursedDetailsForm = DisbursedDetailsForm?.map((field) => { //here we are updating the value
    const dataValue = walletDetails?.data?.[field.name];
  
    if (dataValue !== undefined) {
      return {
        ...field,
        defaultValue: dataValue,
      };
    }
    return field;
  });

  const updatedLoanplatformsForm = PartnerBankLoans?.map((field) => { //here we are updating the value
    const dataValue = walletDetails?.data?.[field.name];
    if (dataValue !== undefined) {
      return {
        ...field,
        defaultValue: dataValue,
      };
    }
    return field;
  });

  const updatedDisbursedCasesForm = DisbursedCases?.map((field) => { //here we are updating the value
    const dataValue = walletDetails?.data?.[field.name];
    if (dataValue !== undefined) {
      return {
        ...field,
        defaultValue: dataValue,
      };
    }
    return field;
  });

    
  function generateDropdownOptions(field) {
    if (field.id === "home_loan_cases" ) {
      const rangeOptions = Array.from({ length: 10 }, (_, i) => i + 1);
  
      return rangeOptions.map((value) => ({
        id: value.toString(),
        label: value.toString(),
        value: value.toString(),
      }));
    } else if (field.id === "team_size") {
      const teamSizeOptions = [
        { id: "1-3", label: "1-3", value: "1-3" },
        { id: "3-5", label: "3-5", value: "3-5" },
        { id: "5-7", label: "5-7", value: "5-7" },
        { id: "7-9", label: "7-9", value: "7-9" },
        { id: "9-11", label: "9-11", value: "9-11" },
      ]
      return teamSizeOptions;
    }

    else if(field.id === "other_products"){
      return otherProducts.map((option) => ({
        id: option?.product,
        label: option?.product,
        value: option?.product
      }));
    }
    return [];
  }

  const handleSubmit = async (model) => {
    setIsLoading(true);
    const additionInModel = {
      ...model,
      office_status:disburseDetails?.office_status,
      loan_platforms:loanplatforms?.loan_platforms,
      disbursement_duration:disburseDetails?.disbursement_duration,
      pref_bankers:disburseDetails?.pref_bankers,
      other_products: disburseDetails?.other_products!==""?JSON.stringify(disburseDetails?.other_products):"",

    }
    const filteredModel = Object.fromEntries(
      Object.entries(additionInModel).filter(([key, value]) =>value!=="" && value !== null && value !== undefined)
    );
    const payload = {
      partner_id: leadInfo?.leadInfo?.id,
      user_id: leadInfo?.leadInfo?.user_id,
      source: "PST",
      home_loan_cases: model?.home_loan_cases,
      loan_ticket_size: model?.loan_ticket_size,
      other_products: disburseDetails?.other_products!==""?JSON.stringify(disburseDetails?.other_products):"",
      other_products_details: disburseDetails?.other_products?.includes("Others") ? model?.other_products_details : "",
      team_size: model?.team_size,
      pref_bankers: JSON.stringify(disburseDetails?.pref_bankers),
      office_status: filteredModel?.office_status,
      business_data: JSON.stringify(filteredModel),
    };
  
    const saveWalletInfo = async (payload) => {
      try {
        const filteredPayload = Object.fromEntries(
          Object.entries({
            partner_id: payload?.partner_id,
            user_id: payload?.user_id,
            source: payload?.source,
            is_completed: "1",
            home_loan_cases: payload?.home_loan_cases ? Number(payload?.home_loan_cases) : undefined,
            loan_ticket_size: payload?.loan_ticket_size,
            other_products: payload?.other_products,
            other_products_details: payload?.other_products_details,
            team_size: payload?.team_size,
            pref_bankers: payload?.pref_bankers,
            office_status: payload?.office_status,
            business_data: payload?.business_data,
          }).filter(([_, value]) => value !== null && value !== undefined && value !== "")
        );
    
        const response = await MasterService.post('/partner/partner/save-wallet-info', filteredPayload);
        setIsLoading(false);
        setAllowSubmit(false);
        if(response?.data?.status===200){
          toast.success("Business sizing details saved successfully");
        } else {
          
          toast.error("Error in saving details. Please try again.");
        }
      } catch (error) {
        console.error('Error saving wallet info:', error?.message);
        throw error;
      }
    };
  
    try {
     await saveWalletInfo(payload);
    } catch (error) {
      console.error("Failed to save wallet info:", error);
    }
  };

  if(isLoading) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }
  
  
  
    return (
    <div className="search-wrap">
      <Formsy
        className="lead-form-filed"
        autoComplete="off"
        onValidSubmit={handleSubmit}
      >
        <div className="row">
          {updatedDisbursedDetailsForm?.map((field) =>
            ["text", "pattern-format", "number"].includes(field.type) &&(field.id !== "other_products_details") ? (
              <fieldset className="form-filed col-md-4" style={{marginBottom:20}}>
                <div  className="partnerheading">{field.label}</div>
                <FormsyInputField
                  id={field.id}
                  name={field.name}
                  type={field.type}
                  placeholder={""}
                  format={field.ApplyNumberformat ? field.format : ""}
                  validations={field.validations}
                  validationError={field.validationError}
                  value={disburseDetails[field.name]}
                  onChange={(event) => handleInputChange(event, field.name)} 
                  />
              </fieldset>
            ) : ["text", "pattern-format", "number"].includes(field.type)  &&
  (field.id === "other_products_details" && disburseDetails?.other_products?.includes("Others"))? (
              <fieldset className="form-filed col-md-4" style={{marginBottom:20}}>
                <div>{field.label}</div>
                <FormsyInputField
                  id={field.id}
                  name={field.name}
                  type={field.type}
                  placeholder={""}
                  format={field.ApplyNumberformat ? field.format : ""}
                  validations={field.validations}
                  value={disburseDetails[field.name]}
                  onChange={(event) => handleInputChange(event, field.name)} 
                  />
              </fieldset>
            ) : 
            field.type === "dropdown" && field.id!=="cra_state" && field.id!=="cra_city" ? (
              
                <fieldset className="single-select col-md-4" style={{ marginBottom: 20 }}>
                  <div  className="partnerheading">{field.label}</div>
                  <FormsySelect
                    name={field.name}
                    id={field.id}
                    inputProps={{
                      options: generateDropdownOptions(field),  
                      className: "react-select",
                      classNamePrefix: "react-select",
                      value: generateDropdownOptions(field).find(
                        ({ id }) => id === String(disburseDetails[field.name])  
                      ),
                      // isDisabled: field.readOnly 
                    }}
                    value={disburseDetails[field.name]}  
                    onChange={(data) =>
                      handleSelectChange(data, field.name)
                    }
                  />
                </fieldset>

            
            ) :field.type === "dropdown" && (field.id==="cra_state"|| field.id==="cra_city" )? (
              <fieldset className="single-select col-md-4" style={{ marginBottom: 20 }}>
                <div  className="partnerheading">{field.label}</div>
                <FormsySelect
                  name={field.name}
                  id={field.id}
                  inputProps={{
                    options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                    // placeholder: field.label,
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value:
                      ALL_OPTIONS &&
                      ALL_OPTIONS[field.optionsKey] &&
                      ALL_OPTIONS[field.optionsKey].filter(
                        ({ value }) => value == disburseDetails[field.name]
                      ),
                    isDisabled: field.readOnly,
                  }}
                  required={field.required}
                  value={disburseDetails[field.name]}  
                  onChange={(data) =>
                    handleSelectChange(data, field.name)
                  }
                />
              </fieldset>
            ) :field.type === "multiselect" ? (
              <fieldset class="single-select col-md-4">
                <div  className="partnerheading">{field.label}</div>
                <MultiSelect
                  options={generateDropdownOptions(field)}
                  placeholder={field.label}
                  name={field.name}
                  id={field.id}
                  optionLabel="label"
                  optionValue="id"
                  values={disburseDetails[field.name]}
                  onChange={handleMultiSelect}
                  // isDisabled={field.readOnly || (activeTab != 'tranchtype_0')}
                  isMulti
                />
              </fieldset>
            ) : null

          
          )}
        </div>
          <div className="partnercommonpd">
          <div className="partnerheading">Does the partner have his own office?</div>
           <div className='loanfulfillment' style={{margin:"20px 0px"}}>
              <div className='loaninput'>
                <input type="radio"  id="yes" name="office_status" checked={disburseDetails?.office_status == "1"} onChange={handleRadioChange} />
                <label htmlFor='yes'>Yes</label>
              </div>
              <div className='loaninput'>
                <input type="radio"  id="no" name="office_status"  checked={disburseDetails?.office_status == "0"} onChange={handleRadioChange} />
                <label htmlFor='no'>No</label>
              </div>
            </div>

            <div className="partnerheading">Are you currently working with any other loan platforms?</div>
            
            <div className='loanfulfillment' style={{margin:"20px 0px"}}>
              <div className='loaninput'>
                <input type="checkbox" id="andromeda" name="andromeda" value="andromeda" checked={loanplatforms?.loan_platforms?.includes("andromeda")} onChange={handleMultiCheckbox} />
                <label htmlFor='andromeda'>Andromeda</label>
              </div>
              <div className='loaninput'>
                <input type="checkbox" id="saarthi" name="saarthi" value="saarthi" checked={loanplatforms?.loan_platforms?.includes("saarthi")} onChange={handleMultiCheckbox} />
                <label htmlFor='saarthi'>Saarthi</label>
              </div>
              <div className='loaninput'>
                <input type="checkbox" id="basic" name="basic" value="basic" checked={loanplatforms?.loan_platforms?.includes("basic")} onChange={handleMultiCheckbox} />
                <label htmlFor='basic'>BASIC</label>
              </div>
              <div className='loaninput'>
                <input type="checkbox" id="other" name="other" value="other" checked={loanplatforms?.loan_platforms?.includes("other")} onChange={handleMultiCheckbox} />
                <label htmlFor='other'>Other</label>
              </div>
            </div>

            </div>


            <div className="row">
          {updatedLoanplatformsForm.map((field) =>
            ["text", "pattern-format", "number"].includes(field.type)&&loanplatforms?.loan_platforms?.includes("other") ? (
              <fieldset className="form-filed col-md-4" style={{marginBottom:20}}>
                <div  className="partnerheading">{field.label}</div>
                <FormsyInputField
                  id={field.id}
                  name={field.name}
                  type={field.type}
                  placeholder={""}
                  format={field.ApplyNumberformat ? field.format : ""}
                  validations={field.validations}
                  validationError={field.validationError}
                  value={disburseDetails[field.name]}  
                  onChange={(data) => handleInputChange(data, field.name)}
                />
              </fieldset>
            ) : field.type === "dropdown" ? (
              <fieldset className="single-select col-md-4" style={{marginBottom:20}}>
                <div  className="partnerheading">{field.label}</div>
                <FormsySelect
                   name={field.name}
                   id={field.id}
                   inputProps={{
                     options: generateDropdownOptions(field),  
                     className: "react-select",
                     classNamePrefix: "react-select",
                     value: generateDropdownOptions(field).find(
                       ({ id }) => id === String(disburseDetails[field.name])  
                     ),
                     // isDisabled: field.readOnly 
                   }}
                   value={disburseDetails[field.name]}  
                   onChange={(data) => handleSelectChange(data, field.name)} 
                />
              </fieldset>
            ) :field.type === "multiselect" ? (
              <fieldset class="single-select col-md-4">
                <div  className="partnerheading">{field.label}</div>
                <MultiSelect
                  options={ALL_OPTIONS && ALL_OPTIONS[field.optionsKey]}
                  placeholder={field.label}
                  name={field.name}
                  id={field.id}
                  optionLabel="label"
                  optionValue="id"
                  values={disburseDetails[field.name]}
                  onChange={handleMultiSelect}
                  // isDisabled={field.readOnly || (activeTab != 'tranchtype_0')}
                  isMulti
                />
              </fieldset>
            ) : null
          )}
        </div>

        <div className="partnerheading partnercommonpd">What is the typical payout percentage you receive for disbursed cases?</div>

        <div className="row">
          {updatedDisbursedCasesForm.map((field) =>
            ["text", "pattern-format", "number"].includes(field.type) ? (
              <fieldset className="form-filed col-md-3" style={{marginBottom:20}}>
                <FormsyInputField
                  id={field.id}
                  name={field.name}
                  type={field.type}
                  placeholder={""}
                  min={field.id === "min_payout_per_disbursed" ? "1" : disburseDetails["min_payout_per_disbursed"]}
                  label={field.label}
                  format={field.ApplyNumberformat ? field.format : ""}
                  validations={field.validations}
                  validationError={field.validationError}
                  value={disburseDetails[field.name]}
                  onChange={(data) => handleInputChange(data, field.name)}
                />
              </fieldset>
            ) : field.type === "dropdown" ? (
              <fieldset className="single-select col-md-3" style={{marginBottom:20}}>
                <FormsySelect
                  name={field.name}
                  id={field.id}
                  inputProps={{
                    //options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                    placeholder: field.label,
                    className: "react-select",
                    classNamePrefix: "react-select",
                    //value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ id }) => id == splitPaymentDetailData[field.name]),
                    // isDisabled: field.readOnly
                  }}
                />
              </fieldset>
            ) : null
          )}
        </div>
     <div className="partnercommonpd">
        <div className="partnerheading">How long does it usually take for you to receive payouts after a case is disbursed?</div>
        <div className="cibilloanflex">
            {Payouts_TABS.map((tab) => (
              <div
                key={tab.id}
                className={`cibilhometab ${
                  disburseDetails && disburseDetails?.disbursement_duration === tab.value ? "active" : ""
                }`}
                onClick={() => handleTabChange(tab.value)}
              >
                <span>{tab.label}</span>
              </div>
            ))}
          </div>

          <div className="partnerheading">When can we expect you to share your next case file with us?</div>
</div>
        <div className="row">
        <fieldset className="form-filed col-md-3">
                  <FormsyDatePicker
                    name="next_case_expected_date"
                    selectedDate={
                      disburseDetails &&
                      disburseDetails.next_case_expected_date &&
                      new Date(disburseDetails.next_case_expected_date)
                    }
                    placeholder=''
                    value={disburseDetails && disburseDetails?.next_case_expected_date}
                    onDateSelect={(date) => handleDateChange(date, disburseDetails?.next_case_expected_date)}
                    dateFormat="dd MMM, yyyy"
                  />
                </fieldset>
        </div>
        <div className="partnersavebtnflex">
        <button
          type="submit"
          className="partnerregisterbtn"
          disabled={!allowSubmit}
        >
          Submit
        </button>
      </div>
       
      </Formsy>
    </div>
  );
};

export default PartnerBusinessDetailsPage;
