import dateFormat from "dateformat";
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { DOC_STATUS, IS_USER_ACCESS_PAGE, USERS_FOR_VIEW_AMBAK_LEADS, MAKER_ROLE_ID } from '../../config/constants';
import crypto from "../../config/crypto";
import morefilters from "../../webroot/images/morefiltersicon.png";
import MultiSelect from '../elements/MultiSelect';
import { useApolloClient } from '@apollo/client';
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { GET_USER_LIST } from "../../services/role.gql";
import { PARTNER_DROPDOWN_LIST } from "../../services/leadCreate.gql";

let Financier = [], Status = [], SubStatus = [], BankList = [], DateType = [];
let FollowupWith = [
  { value: "customer", label: "Customer" },
  { value: "financir", label: "Financir" },
  { value: "saathi", label: "Saathi" },
  { value: "ambak", label: "Ambak" },
  { value: "yoddha", label: "Yoddha" }
];

let CallingReason = [
  { value: 6, label: "Interested" },
  { value: 25, label: "Not Interested" },
  // { value: 7, label: "Call Back requested" },
  // { value: 8, label: "Visit requested" },
  // { value: 9, label: "Customer Disconnected" },
  // { value: 10, label: "Customer Busy" }
];

const PreLogin = [
  { value: 'kyc_doc', label: 'KYC docs' },
  { value: 'financial_doc', label: 'Financial docs' },
  { value: 'login_doc', label: 'Login Docs' }
];
//---------------------------------------------------------------------------
const accessCondition = IS_USER_ACCESS_PAGE("assign_user", "assign_user")?.is_edit_access
  || IS_USER_ACCESS_PAGE("assign_user", "assign_user")?.is_access_page;
//---------------------------------------------------------------------------

const SEARCH_TYPE = [
  { value: 'lead_id', label: 'Lead ID' },
  // { value: 'first_name', label: 'Customer Name' },
  { value: 'full_name', label: 'Full Name'},
  { value: 'mobile', label: 'Customer Phone' }, 
  { value: 'partner_id', label: 'Partner ID' },
  { value: 'rm_id', label: 'BM ID' },
  { value: 'login_number', label: 'Login ID' },
  { value: 'approval_number', label: 'Sanctioned ID' },
  { value: 'disbursed_id', label: 'Disbursed ID' }
]

const VERIFICATION_STATUS = [
  { id: 2, value: 'login_verified', label: 'Login verified' },
  { id: 2, value: 'login_not_verified', label: 'Login verification pending' },
  { id: 2, value: 'login_verification_failed', label: 'Login verification failed' },
  { id: 3, value: 'sanction_verified', label: 'Sanction verified' },
  { id: 3, value: 'sanction_not_verified', label: 'Sanction verification pending' },
  { id: 3, value: 'sanction_verification_failed', label: 'Sanction verification failed' },
  { id: 4, value: 'disbursal_verified', label: 'Disbursal verified' },
  { id: 4, value: 'disbursal_not_verified', label: 'Disbursal verification pending' },
  { id: 4, value: 'disburse_verification_failed', label: 'Disbursal verification failed' }
];

const DISBURSAL_STATUS = [
  { value: "1", label: "Single Disbursed" },
  { value: "2", label: "Tranch Disbursed" }
]

const IS_DEMO_FILTER = [
  { value: "1", label: "Yes" },
  { value: "0", label: "No" }
]

const FOLLOWUP_STATUS = [
  { value: "1", label: "Yes" },
  { value: "0", label: "No" }
]

const IS_QUALIFIED = [
  { value: "1", label: "Yes" },
  { value: "0", label: "No" }
]

const DATE_TYPE_LIST = [
  { label: "New Lead", value: "created_date" },
  { label: "LogIn", value: "login_date" },
  { label: "Sanctioned", value: "approval_date" },
  { label: "Disbursed", value: "disbursed_date" },
  // { label: "Lost", value: "lost"}
]

const ORDER_BY_LIST = [
  // { id: "customer.first_name", value: "first_name_asc", label: "First name (a-z)", sort: "ASC" },
  { id: "followup_date", value: "followup_date_asc", label: "Followup date ASC", sort: "ASC" },
  { id: "followup_date", value: "followup_date_desc", label: "Followup date DESC", sort: "DESC" },
  { id: "id", value: "id_asc", label: "Lead id ASC", sort: "ASC" },
  { id: "id", value: "id_desc", label: "Lead id DESC", sort: "DESC" },
  // { id: "last_updated_date", value: "status_updated_date_asc", label: "Status update Date ASC", sort: "ASC" },
  // { id: "last_updated_date", value: "status_updated_date_desc", label: "Status update Date DESC", sort: "DESC" },
  // { id: "lead_details.loan_amount", value: "loan_amount_asc", label: "Loan amount ASC", sort: "ASC" },
  // { id: "lead_details.loan_amount", value: "loan_amount_desc", label: "Loan amount DESC", sort: "DESC" },
  { id: "last_updated_date", value: "last_updated_date_asc", label: "Last updated date ASC", sort: "ASC" },
  { id: "last_updated_date", value: "last_updated_date_desc", label: "Last updated date DESC", sort: "DESC" },
]

const LeadListFilter = ({ financers, statuses, subStatusList, handleSearch, setFilterParam, fulfillments, productType, productSubType, propertyType, rmlist, leadPagination }) => {
  let { status: queryStatus, searchtext: search_text, searchtype: searchtype, fulfillment_type, loan_type, loan_sub_type, property_type,assign_to,followup_status,is_qualified } = useParams();
  const [filterData, setFilterData] = useState({});
  const [filtersArr, setFiltersArr] = useState([]);
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [verification_status, setVerificationStatus] = useState(VERIFICATION_STATUS);
  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  const [userList, setUserList] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const client = useApolloClient();

  const { masterdata,lead } = useSelector(({ masterdata,lead }) => ({
    masterdata,lead
  }));
  
  fulfillments = masterdata?.data?.fulfillment_type || [];
  productType = masterdata?.data?.loan_type || [];
  productSubType = masterdata?.data?.loan_sub_type?.map(ob=>{
    return {...ob,value:ob.id}
  }) || [];
  propertyType = masterdata?.data?.property_type || [];
  DateType = masterdata?.data?.sub_status_dates;


  if (Financier.length == 0) {
    financers && financers.map((financer) => {
      Financier.push({ financier_id: financer?.financierlang?.financier_id, financier_name: financer?.financierlang?.financier_name })
    })
  }
  if (BankList.length == 0) {
    let banklistAray = masterdata?.data?.bank_list;
    
    banklistAray && banklistAray.map((ob) => {
      BankList.push({ bank_id: ob?.id, bank_name: ob?.banklang?.bank_name })
    })
  }
  if (Status.length == 0) {
    statuses && statuses.map((status) => {
      if(status?.statuslang) {
        Status.push({ "status_id": status?.statuslang?.status_id, "status_name": status?.statuslang?.status_name })
      } else if(status?.status_id) {
        Status.push({ "status_id": status?.status_id, "status_name": status?.status_name })
      }
    })
  }
  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    const toggleCheckbox = document.getElementById('toggleCheckbox');
    if (toggleCheckbox.checked) {
      toggleCheckbox.checked = false;
    }
  };

  const removeFilter = (key, value, type) => {
    let filterDataObj = filterData;
    let filtersArrObj = filtersArr;
    if (['financier_id', 'status_id', 'sub_status_id'].includes(type)) {
      filterData[type] = filterData[type].filter(item => item != key);
      if (type == 'status_id') {
        let status_ids = [];
        let arr1 = [];
        SubStatus = [];
        status_ids = filterData[type].map(obj => {
          let arr2 = subStatusList.filter(obj2 => obj2.status_id == obj);
          arr1 = arr1.concat(arr2);
          return obj;
        });
        filterVerificationStatus(status_ids);
        let subst = [];
        arr1.map((substatus) => {
          let sub_still_filtered = filterData['sub_status_id'].filter(item => item == substatus.substatuslang.sub_status_id);
          if (sub_still_filtered.length > 0)
            subst.push(sub_still_filtered?.[0]);

          SubStatus.push({ "sub_status_id": substatus.substatuslang.sub_status_id, "sub_status_name": substatus.substatuslang.sub_status_name })
        })
        filterData['sub_status_id'] = subst;
      }

    }
    else {
      if (type === 'date_type') {
        delete filterDataObj["from_date"];
        delete filterDataObj["to_date"];
        setValues([
          "",
          ""
        ])
      }
      delete filterDataObj[type];
    }
    let indx = filtersArr.findIndex(itm => itm.key == key && itm.value == value);
    filtersArr.splice(indx, 1);
    setFilterData({ ...filterDataObj });
    setFiltersArr([...filtersArrObj]);
    searchLead()
  }

  const showFilter = () => {
    setShowMoreFilter(!showMoreFilter)
  }

  const base64DecodeId = (encodedId) => {        
    return parseInt(atob(encodedId), 10);
}

  const handleChange = async (n, v, isMulti, opt, optl) => {
    
    switch (n) {
      case 'followup_with':
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'calling_reason_id':
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'pre_login':
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'bank_id':
        let bank_ids = [];
        bank_ids = v.map(obj => { return obj.bank_id });
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'financier_id':
        let financier_ids = [];
        financier_ids = v.map(obj => { return obj.financier_id });
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'rm_id':
        let rm_ids = [];
        rm_ids = v.map(obj => { return obj.rm_id });
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'status_id':
        // let status_ids = [];
        // let arr1 = [];
        // SubStatus = [];
        // filterVerificationStatus(v);
        // status_ids = v.map(obj => {
        //   let arr2 = (subStatusList) ? subStatusList.filter(obj2 => obj2.status_id == obj.status_id) : [];
        //   arr1 = arr1.concat(arr2);
        //   return obj.status_id;
        // });

        // arr1.map((substatus) => {
        //   SubStatus.push({ "sub_status_id": substatus.substatuslang.sub_status_id, "sub_status_name": substatus.substatuslang.sub_status_name })
        // })
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'sub_status_id':
        let sub_status_ids = [];
        sub_status_ids = v.map(obj => { return obj.sub_status_id });
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'date_type':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'partner_id':
        setFilterData((prev) => ({ ...prev, [n]: v.value, search_text: v.value, search_type:"partner_id" }));
        break;
      case 'verification_status':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'doc_status':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'from_date':
        setFilterData((prev) => ({ ...prev, [n]: v, 'to_date': '' }));
        break;
      case 'to_date':
        setFilterData((prev) => ({ ...prev, [n]: v }));
        break;
      case 'fulfillment_type':
        setFilterData((prev) => ({ ...prev, [n]: v.id }));
        break;
      case 'loan_type':
        setFilterData((prev) => ({ ...prev, [n]: v.id }));
        break;
      case 'tranche_type':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'is_demo':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'property_type':
        setFilterData((prev) => ({ ...prev, [n]: v.id }));
        break;
      case 'search_text':
        setFilterData((prev) => ({ ...prev, [n]: v.target.value }));
        break;
      case 'search_type':
        setFilterData((prev) => ({ ...prev, search_text: "", [n]: v.value }));
        break;
      case 'loan_sub_type':
        setFilterData((prev) => ({ ...prev, [n]: v.id }));
        break;
      case 'column':
        setFilterData((prev) => ({ ...prev, [n]: v.id, sort: v.sort }));
        break;
      case 'assign_to':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'followup_status':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'is_qualified':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
    }
  }

  const getPartnerData = () => {
    executeGraphQLQuery(PARTNER_DROPDOWN_LIST, client)
      .then((result) => {
        if (result?.data) {
          const jsonData = JSON.parse(result?.data?.partner_list_dropdown);
          if (jsonData?.status === 200) {
            const partnerList = jsonData.data.map((obj) => {
              return {
                id: obj.partnerId,
                label: `${obj.PartnerName || "N/A"} - ${obj.partnerId}`,
                value: obj.partnerId,
              };
            });

            setPartnerData(partnerList);
          }
        }
      })
      .catch((errors) => {
        console.log(errors.message);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getPartnerData();
  }, []);

  useEffect(() => {
    SubStatus = [];
    if (queryStatus == 'search_text' && search_text) {
      setFilterData({ search_text: search_text });
      setFilterData((prev) => ({ ...prev, ['search_type']: searchtype }));
    } else if (queryStatus) {
      let defulatStatus = crypto.decode(queryStatus);
      let urlparams = defulatStatus?.split('-') || [];
      let loan_status = (defulatStatus != 'followup' && urlparams.length === 1 )? defulatStatus : 0;
      const queryFilterStatusIds = typeof loan_status === 'string' ? loan_status.split(",") : loan_status && Array.isArray(loan_status) && loan_status.length ? loan_status : [];
      if (queryFilterStatusIds.length) { 
        setFilterData((prev) => ({ ...prev, status_id: queryFilterStatusIds.map(Number) }));
        filterVerificationStatus([loan_status].map(Number), true);
      } else if (defulatStatus == 'followup') setFilterData((prev) => ({ ...prev, followup_type: 'all' }));
      else if (urlparams.length === 2) setFilterData((prev) => ({ ...prev, [urlparams[0]]: urlparams[1] }));
      if (fulfillment_type) {
        setFilterData((prev) => ({ ...prev, fulfillment_type: fulfillment_type }));
      }
      if (assign_to) {
        setFilterData((prev) => ({ ...prev, assign_to: base64DecodeId(assign_to) }));
      }
      if (loan_type) {
        setFilterData((prev) => ({ ...prev, loan_type: loan_type }))
      }
      if (loan_sub_type) {
        setFilterData((prev) => ({ ...prev, loan_sub_type: loan_sub_type }))
      }
    }
    if (queryStatus == undefined) {
      setFilterData((prev) => ({ ...prev, is_demo: "0" }));
      searchLead({});
    } else if (statuses?.length && queryStatus != undefined) {
      setFilterData((prev) => ({ ...prev, is_demo: "0" }));
      searchLead({});
    }
    if (followup_status) {
      setFilterData((prev) => ({ ...prev, followup_status: followup_status }));
    } 
    if (is_qualified) {
      setFilterData((prev) => ({ ...prev, is_qualified: is_qualified }));
    } 
  }, [queryStatus, statuses]);

  const filterVerificationStatus = (selectedStatus, calltype = false) => {
    let filterVerificationStatus = VERIFICATION_STATUS;
    if (selectedStatus.length) {
      selectedStatus = !calltype ? selectedStatus.map(data => data.status_id) : selectedStatus;
      filterVerificationStatus = VERIFICATION_STATUS.filter(data => selectedStatus.includes(data.id));
    }
    setVerificationStatus(filterVerificationStatus);
  }

  const searchLead = (e) => {

    let filterDetails = [];
    
    if (filterData && filterData.search_text) {
      filterDetails.push({ type: 'search_text', value: 'Search-' + filterData.search_text, key: 0 })
    }

    if (filterData && filterData.financier_id && filterData.financier_id.length) {
      filterData.financier_id.map((element) => {
        let finance = financers && financers.filter((obj) => obj.financierlang.financier_id == element);
        if (finance && finance[0] && finance[0].financierlang && finance[0].financierlang && finance[0].financierlang.financier_name) {
          filterDetails.push({ type: 'financier_id', value: 'Financier-' + finance[0].financierlang.financier_name, key: finance[0].financierlang.financier_id })
        }
      })
    }

    if (filterData && filterData.rm_id && filterData.rm_id.length) {
      filterData.rm_id.map((element) => {
        let rmdetail = rmlist && rmlist.filter((obj) => obj.id == element);
        if (rmdetail && rmdetail[0] && rmdetail[0].id && rmdetail[0].label) { 
          filterDetails.push({ type: 'rm_id', value: 'rmlist-' + rmdetail[0].label, key: rmdetail[0].id })
        }
      })           
    }

    if (filterData && filterData.status_id && filterData.status_id.length) {
      filterData?.status_id.map((element) => {
        let status = statuses.filter((obj) => { return obj.statuslang ? obj.statuslang?.status_id == element : obj?.status_id == element});
        if (status && Array.isArray(status) && status.length && status[0].status_id) {
          filterDetails.push({ type: 'status_id', value: 'Status-' + status[0].status_name, key: status[0].status_id })
        }
      })
    }

    if (filterData && filterData.sub_status_id && filterData.sub_status_id.length) {
      filterData.sub_status_id.map((element) => {
        let sub_status = subStatusList && subStatusList.filter((obj) => obj.substatuslang.sub_status_id == element);
        if (sub_status && sub_status[0] && sub_status[0].substatuslang && sub_status[0].substatuslang && sub_status[0].substatuslang.sub_status_name) {
          filterDetails.push({ type: 'sub_status_id', value: 'Sub Status-' + sub_status[0].substatuslang.sub_status_name, key: sub_status[0].substatuslang.sub_status_id })
        }
      })
    }

    if (filterData && filterData.date_type) { 
      if (!filterData.from_date) {
        toast.error("Please enter valid start date");
        return;
      }
      if (!filterData.to_date) {
        toast.error("Please enter valid to date");
        return;
      }
      filterDetails.push({ type: 'date_type', value: 'DateType-' + filterData.date_type, key: 0 })
    }
    if (filterData && filterData.to_date) {
      filterDetails.push({ type: 'date_type', value: 'To Date-' + dateFormat(filterData.to_date, 'dd mmm yyyy'), key: 0 })
    }

    if (filterData && filterData.fulfillment_type) {
      filterDetails.push({ type: 'fulfillment_type', value: 'Fulfillment Type-' + filterData.fulfillment_type, key: 0 })
    }

    if (filterData && filterData?.loan_type) {
      filterDetails.push({ type: 'loan_type', value: 'Product Type-' + filterData.loan_type, key: filterData.loan_type })
    }

    if (filterData && filterData?.loan_sub_type) {
      filterDetails.push({ type: 'loan_sub_type', value: 'Product Sub Type-' + filterData?.loan_sub_type, key: filterData?.loan_sub_type })
    }

    if (filterData && filterData.search_type) {
      filterDetails.push({ type: 'search_type', value: 'Search Type-' + filterData.search_type, key: 0 })
    }

    if (filterData && filterData.verification_status) {
                                                                                                
      let statusLabel = VERIFICATION_STATUS.filter(ob=>ob.value ==filterData.verification_status)?.[0]?.label || ''; 
      filterDetails.push({ type: 'verification_status', value: 'Verificattion Status-' + statusLabel, key: filterData.verification_status })
    }

    if (filterData && filterData.tranche_type) {
      filterDetails.push({ type: 'tranche_type', value: 'Disbursal Type-' + filterData.tranche_type, key: filterData.tranche_type })
    } 
    if (filterData && filterData.hasOwnProperty('is_demo')) {
      filterDetails.push({ type: 'is_demo', value: 'Is Demo-' + IS_DEMO_FILTER?.filter(data=> data?.value === filterData.is_demo)?.map(v=>v?.label), key: filterData.is_demo })
    }else{
      filterDetails.push({ type: 'is_demo', value: 'Is Demo-No' , key: '0' })
    }

    if (filterData && filterData?.property_type) {
      filterDetails.push({ type: 'property_type', value: 'Property Type-' + filterData.property_type, key: filterData.property_type })
    }

    if (filterData && filterData.doc_status) {
      filterDetails.push({ type: 'doc_status', value: 'Doc Status-' + filterData.doc_status, key: 0 })
    }
    if (filterData && filterData.partner_id) {
      filterDetails.push({ type: 'partner_id', value: 'Partner id-' + filterData.partner_id, key: 0 })
    }
    if (filterData && filterData.assign_to) {
      filterDetails.push({ type: 'assign_to', value: 'Assign E2E Sales-' + filterData.assign_to, key: 0 })
    }
    if (filterData && filterData.followup_status) {
      filterDetails.push({ type: 'followup_status', value: 'Followup Search-' + FOLLOWUP_STATUS?.filter(data=> data?.value === filterData?.followup_status)?.map(v=>v?.label), key: filterData?.followup_status })
    }
    if (filterData && filterData.is_qualified) {
      filterDetails.push({ type: 'is_qualified', value: 'Qualified-' + IS_QUALIFIED?.filter(data=> data?.value === filterData?.is_qualified)?.map(v=>v?.label), key: filterData?.is_qualified })
    }

    setFiltersArr(filterDetails)
    handleSearch(filterData)
    // const toggleCheckbox = document.getElementById('toggleCheckbox');
    // toggleCheckbox.checked = false;
  }

  document.addEventListener("click", function(event) {
    const isTargetSelectOption = event.target.role == "option"
    const moreFilterDiv = document.querySelector(".more-filter");
    if (!moreFilterDiv?.contains(event.target) && !isTargetSelectOption) {
      const toggleCheckbox = document.getElementById("toggleCheckbox");
      if(toggleCheckbox) toggleCheckbox.checked = false;
    }
  });
  
  const resetSearchLead = (e) => {
    document.getElementById("more-filter-dropdown").classList.remove('show');
    setFilterData({});
    setFiltersArr([]);
    setFilterParam();
    setValues(["", ""]);
    handleSearch({});
    filterVerificationStatus([])
  }

  const [values, setValues] = useState(["", ""]);

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setFilterData((prev) => ({
      ...prev,
      from_date: start,
      to_date: end
    }));
  }
  const handleKeyDown = (e)=>{ 
    if (e.key === 'Enter') {
      searchLead();
    }
  }
  let selfAccess = IS_USER_ACCESS_PAGE("self_fullfill_bucket", "self_fullfill_bucket")?.is_edit_access;
  let ambakAccess = IS_USER_ACCESS_PAGE("ambak_fullfill_backet", "ambak_fullfill_backet")?.is_edit_access; 

  let fulfillments_option =  [] 
  for(let item of fulfillments){
    if((item.id =='self' && selfAccess === 1) || (item.id =='ambak' && ambakAccess === 1)){
      fulfillments_option.push({
        ...item,
        value: item.id
      })
    }
  } 
  let newFiltersArr = (selfAccess === 1)? filtersArr: filtersArr.filter(ob=>ob.type!="fulfillment_type"); 

  // -----------------------------------------------------------------

  const getUserList = async () => {
    let user_list = [],userlist=[];
    await executeGraphQLQuery(GET_USER_LIST(5,1),client).then((response) => {
      if (response && response.data && response.data.get_user_list) {
        user_list = response.data.get_user_list?.filter(obj => obj.status === '1');
        user_list && user_list?.map((v)=>{
          userlist.push({value:v.user_id,label:v.name,role_id:v.role_assigned_ids})
          return v;
        })
        setUserList(userlist);
      }
     }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserList();
    };
    fetchData();
  }, []);

  let maker_user_list = userList.filter(data => data.role_id.includes(MAKER_ROLE_ID) && data.value != UserInfo?.id); 
 
  return (
    <React.Fragment>
      <div className="top-heading" >
        <div className="filterflex">
          <h1 style={{ marginLeft: "7px" }}>Finance Cases</h1>
          <div className="filterinput" onClick={(e)=>handleOutsideClick()}>
            <div>
              <fieldset className="single-select">
                <div className="material">
                  <Select
                    options={SEARCH_TYPE}
                    placeholder={"Search Type"}
                    name={"search_type"}
                    optionLabel="label"
                    optionValue="value"
                    value={
                      filterData && filterData["search_type"]
                        ? SEARCH_TYPE.filter(
                            ({ value }) => value === filterData["search_type"]
                          )
                        : { value: "lead_id", label: "Lead Id" }
                    }
                    onChange={(e) => handleChange("search_type", e)}
                  />
                </div>
              </fieldset>
            </div>
            <div>
              <fieldset className="search-bx">
                <div className="material">
                  <input
                    id=""
                    type="text"
                    name="search_text"
                    value={filterData.search_text || ""}
                    onChange={(e) => handleChange("search_text", e)}
                    className="form-input-new"
                    title={"SEARCH_TEXT"}
                    placeholder="Search" 
                    onKeyDown={handleKeyDown} 
                  />
                  <i className="ic-search"></i>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div className="loan-filter-bg">
        <div className="top-filter-bg">
          <div className="form-tab-right-panel">
            <div className="row">
              <div className="col-sm-2">
              <div className="filter-title">Status</div>
                <fieldset className="multiselect-dropDown">
                  <div className="material">
                    <MultiSelect
                      options={Status}
                      placeholder={"Status"}
                      name={"status_id"}
                      multiSelectName={"lead_filter_status_id"}
                      optionLabel="status_name"
                      optionValue="status_id"
                      values={filterData.status_id}
                      onChange={handleChange}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-sm-2">
              <div className="filter-title">Verification Status</div>
                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      options={verification_status}
                      placeholder={"Verification Status"}
                      name={"verification_status"}
                      optionLabel="label"
                      optionValue="value"
                      value={
                        filterData && filterData["verification_status"]
                          ? VERIFICATION_STATUS.filter(
                              ({ value }) =>
                                value === filterData["verification_status"]
                            )
                          : ""
                      }
                      onChange={(e) => handleChange("verification_status", e)}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-sm-2">
              <div className="filter-title">Bank</div>
                <fieldset className="multiselect-dropDown">
                  <div className="material">
                    <MultiSelect
                    
                      options={BankList}
                      placeholder={"Bank"}
                      name={"bank_id"}
                      optionLabel="bank_name"
                      optionValue="bank_id"
                      values={filterData.bank_id}
                      onChange={handleChange}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-sm-2">
              <div className="filter-title">Disbursed Type</div>
                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      options={DISBURSAL_STATUS}
                      placeholder={"Disbursed Type"}
                      name={"tranche_type"}
                      optionLabel="label"
                      optionValue="value"
                      value={
                        filterData && filterData["tranche_type"]
                          ? DISBURSAL_STATUS.filter(
                              ({ value }) =>
                                value === filterData["tranche_type"]
                            )
                          : ""
                      }
                      onChange={(e) => handleChange("tranche_type", e)}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-sm-2">
                <div className="more-filter">
                  <label className="morefilterbtn" htmlFor="toggleCheckbox">
                    More filter
                    <img src={morefilters} style={{marginLeft:5}} alt='icon' width={20} height={20} />
                  </label>
                  <input type="checkbox" id="toggleCheckbox" />
                  <div id="filterCard">
                    <div className='row'>
                      <div className="col-sm-6">
                      <div className="filter-title">Partner Name</div>
                        <fieldset className="single-select">
                          <div className="material">
                            <Select
                              options={partnerData}
                              placeholder={"Partner Name"}
                              name={"partner_id"}
                              optionLabel="label"
                              optionValue="value"
                              value={
                                filterData && filterData["partner_id"]
                                  ? partnerData.filter(
                                      ({ value }) =>
                                        value === filterData["partner_id"]
                                    )
                                  : ""
                              }
                              onChange={(e) => handleChange("partner_id", e)}
                              menuShouldScrollIntoView={false}
                            />
                          </div>
                        </fieldset>
                      </div>

                      <div className="col-sm-6">
                      <div className="filter-title">Select RM</div>
                        <fieldset className="multiselect-dropDown" >
                          <div className="material">
                            <MultiSelect
                              options={rmlist}
                              placeholder={"Select RM"}
                              name={"rm_id"}
                              optionLabel="label"
                              optionValue="id"
                              values={filterData.rm_id}
                              onChange={handleChange}
                            />
                          </div>
                        </fieldset>
                      </div>
                      {
                        selfAccess === 1 ?
                        <div className="col-sm-6">
                          <div className="filter-title">Product Type</div>
                          <fieldset className="single-select">
                            <div className="material">
                            <Select
                              options={productType}
                              placeholder={"Product Type"}
                              name={"loan_type"}
                              optionLabel="label"
                              optionValue="id"
                              value={
                                filterData && filterData["loan_type"]
                                  ? productType.find(({ id }) => id === filterData["loan_type"])
                                  : null
                              }
                              onChange={(e) => handleChange("loan_type", e)}
                              styles={{
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                                  color: 'black',
                                }),
                              }}
                              menuShouldScrollIntoView={false}
                            />

                            </div>
                          </fieldset>
                        </div> : null
                      }
                     
                      <div className="col-sm-6">
                      <div className="filter-title">Product Sub Type</div>
                        <fieldset className="single-select">
                          <div className="material">
                            <Select
                              options={productSubType}
                              placeholder={"Product Sub Type"}
                              name={"loan_sub_type"}
                              optionLabel="label"
                              optionValue="id"
                              menuShouldScrollIntoView={false}
                              value={
                                filterData && filterData["loan_sub_type"]
                                  ? productSubType.filter(
                                      ({ id }) =>
                                        id === filterData["loan_sub_type"]
                                    )
                                  : ""
                              }
                              onChange={(e) => handleChange("loan_sub_type", e)}
                              styles={{
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                                  color: 'black',
                                }),
                              }}
                            />
                          </div>
                        </fieldset>
                      </div>

                      <div className="col-sm-6">
                      <div className="filter-title">Property Type</div>
                        <fieldset className="single-select">
                          <div className="material">
                            <Select
                              options={propertyType}
                              placeholder={"Property Type"}
                              name={"property_type"}
                              optionLabel="label"
                              optionValue="id"
                              value={
                                filterData && filterData["property_type"]
                                  ? propertyType.filter(
                                      ({ id }) =>
                                        id === filterData["property_type"]
                                    )
                                  : ""
                              }
                              onChange={(e) => handleChange("property_type", e)}
                              menuShouldScrollIntoView={false}
                            />
                          </div>
                        </fieldset>
                      </div>

                      <div className="col-sm-6">
                        {!USERS_FOR_VIEW_AMBAK_LEADS.includes(
                          UserInfo?.email
                        ) ? (
                          <>
                          <div className="filter-title">Fullfillment</div>
                          <fieldset className="single-select">
                            <div className="material">
                              <Select
                                options={fulfillments_option}
                                placeholder={"Fulfillment"}
                                name={"fulfillment_type"}
                                optionLabel="label"
                                optionValue="id"
                                value={
                                  filterData && filterData["fulfillment_type"]
                                    ? fulfillments_option.filter(
                                        ({ id }) =>
                                          id === filterData["fulfillment_type"]
                                      )
                                    : ""
                                }
                                onChange={(e) =>
                                  handleChange("fulfillment_type", e)
                                }
                                menuShouldScrollIntoView={false}
                              />
                            </div>
                          </fieldset>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-sm-6">
                      <div className="filter-title">Doc Status</div>
                        <fieldset className="single-select">
                          <div className="material">
                            <Select
                              options={DOC_STATUS}
                              placeholder={"Doc Status"}
                              name={"doc_status"}
                              optionLabel="label"
                              optionValue="value"
                              value={
                                filterData && filterData["doc_status"]
                                  ? DOC_STATUS.filter(
                                      ({ value }) =>
                                        value === filterData["doc_status"]
                                    )
                                  : ""
                              }
                              onChange={(e) => handleChange("doc_status", e)}
                              menuShouldScrollIntoView={false}
                            />
                          </div>
                        </fieldset>
                      </div>
                      <div className="col-sm-6">
                      <div className="filter-title">IS Demo</div>
                        <fieldset className="single-select">
                          <div className="material">
                            <Select
                              options={IS_DEMO_FILTER}
                              placeholder={"IS Demo"}
                              name={"is_demo"}
                              optionLabel="label"
                              optionValue="value"
                              value={
                                filterData && filterData["is_demo"]
                                  ? IS_DEMO_FILTER.filter(
                                      ({ value }) =>
                                        value === filterData["is_demo"]
                                    )
                                  : ""
                              }
                              onChange={(e) => handleChange("is_demo", e)}
                              menuShouldScrollIntoView={false}
                            />
                          </div>
                        </fieldset>
                      </div>

                      <div className="col-sm-6">
                      <div className="filter-title">Select Date Type</div>
                        <fieldset className="single-select">
                          <div className="material">
                            <Select
                              options={DATE_TYPE_LIST}
                              placeholder={"Select Date Type"}
                              name={"date_type"}
                              value={
                                filterData && filterData["date_type"]
                                  ? DATE_TYPE_LIST.filter(
                                      ({ value }) =>
                                        value === filterData["date_type"]
                                    )
                                  : ""
                              }
                              onChange={(e) => handleChange("date_type", e)}
                              menuShouldScrollIntoView={false}
                            />
                          </div>
                        </fieldset>
                      </div>
                      <div className="col-sm-6">
                      <div className="filter-title">Select Date Range</div>
                        <fieldset className="single-select">
                          <div className="datepicker">
                            <DatePicker
                              selected={filterData?.from_date}
                              onChange={(date) => onChangeDate(date)}
                              startDate={filterData?.from_date}
                              endDate={filterData?.to_date}
                              selectsRange
                              placeholderText={"Select Date Range"}
                              dropdownMode="select"
                              showMonthDropdown
                              showYearDropdown
                              peekNextMonth
                              minDate={null}
                              maxDate={new Date()}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              isClearable={true}
                            />
                          </div>
                        </fieldset>
                      </div>
                      
                      <div className="col-sm-6">
                      <div className="filter-title">Select E2E Sales</div>
                        <fieldset className="single-select">
                          <div className="material" title={userList.filter((v) => (v.value === lead.assign_to))?.[0]?.label}>            
                            <Select
                              options={accessCondition ===1 ? maker_user_list : []}
                              placeholder={"Select E2E Sales"}
                              name={'assign_to'}
                              optionLabel="label"
                              optionValue="value"
                              className='react-select-table'
                              classNamePrefix='react-select-table'
                              value={userList.filter((v) => (v.value === lead.assign_to)) }
                              onChange={(e) => handleChange("assign_to", e)}
                              menuShouldScrollIntoView={false}
                            />
                          </div>
                        </fieldset>
                      </div>
                       
                      <div className="col-sm-6">
                      <div className="filter-title">Followup Search</div>
                        <fieldset className="single-select">
                          <div className="material">
                            <Select
                              options={FOLLOWUP_STATUS}
                              placeholder={"Followup Search"}
                              name={"followup_status"}
                              optionLabel="label"
                              optionValue="value"
                              value={
                                filterData && filterData["followup_status"]
                                  ? FOLLOWUP_STATUS.filter(
                                      ({ value }) =>
                                        value === filterData["followup_status"]
                                    )
                                  : ""
                              }
                              onChange={(e) => handleChange("followup_status", e)}
                              menuShouldScrollIntoView={false}
                            />
                          </div>
                        </fieldset>
                      </div>

                      <div className="col-sm-6">
                      <div className="filter-title">Qualified</div>
                        <fieldset className="single-select">
                          <div className="material">
                            <Select
                              options={IS_QUALIFIED}
                              placeholder={"Qualified"}
                              name={"is_qualified"}
                              optionLabel="label"
                              optionValue="value"
                              value={
                                filterData && filterData["is_qualified"]
                                  ? IS_QUALIFIED.filter(
                                      ({ value }) =>
                                        value === filterData["is_qualified"]
                                    )
                                  : ""
                              }
                              onChange={(e) => handleChange("is_qualified", e)}
                              menuShouldScrollIntoView={false}
                            />
                          </div>
                        </fieldset>
                      </div>

                      <div className="col-sm-6">
                      <div className="filter-title">Is Interested</div>
                        <fieldset className="multiselect-dropDown">
                          <div className="material">
                            <MultiSelect
                              options={CallingReason}
                              placeholder={"Is Interested"}
                              name="calling_reason_id"
                              optionLabel="label"
                              optionValue="value"
                              values={filterData?.calling_reason_id}
                              onChange={handleChange}
                            />
                          </div>
                        </fieldset>
                      </div>

                      <div className="col-sm-6">
                      <div className="filter-title">Pendancy On</div>
                        <fieldset className="multiselect-dropDown">
                          <div className="material">
                            <MultiSelect
                              options={FollowupWith}
                              placeholder={"Pendancy On"}
                              name="followup_with"
                              optionLabel="label"
                              optionValue="value"
                              values={filterData?.followup_with}
                              onChange={handleChange}
                            />
                          </div>
                        </fieldset>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-2">
                <div className="more-filter">
                  <div className="filter-option">
                    <Dropdown id="more-filter-dropdown">
                      <div className="text-btn d-flex">
                        <div className="btn-submit-reset top-btn-none pull-right">
                          <button onClick={searchLead} className="btn-primary">
                            Apply
                          </button>
                          <button
                            onClick={resetSearchLead}
                            className="btn-reset"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className="col-sm-2">
              <div className="filter-title">Sort by</div>
                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      options={ORDER_BY_LIST}
                      placeholder={"Sort by"}
                      name={"column"}
                      optionLabel="label"
                      optionValue="value"
                      value={
                        filterData && filterData["column"]
                          ? ORDER_BY_LIST.filter(
                              ({ id, sort }) =>
                                id === filterData["column"] && sort === filterData["sort"]
                            )
                          : ""
                      }
                      onChange={(e) => handleChange("column", e)}
                      
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-sm-2">
              <div className="filter-title">Docs Status</div>
                <fieldset className="multiselect-dropDown">
                  <div className="material">
                    <MultiSelect
                      options={PreLogin}
                      placeholder={"Docs Status"}
                      name="pre_login"
                      optionLabel="label"
                      optionValue="value"
                      values={filterData?.pre_login}
                      onChange={handleChange}
                    />
                  </div>
                </fieldset>
              </div>
              
            </div>
          </div>
          <div className="selected-chips">
            <div className="chipsflex">
            <ul>
              {newFiltersArr &&
                newFiltersArr.length > 0 &&
                newFiltersArr.map((element, key) => (
                  <li key={key}>
                    {element.value}
                    <span>
                      <i
                        className="ic-clearclose"
                        onClick={() =>
                          removeFilter(element.key, element.value, element.type)
                        }
                      ></i>
                    </span>
                  </li>
                ))}
            </ul>
            {leadPagination ? (
              <p className="showingpage">
                Showing {leadPagination.currentPageCount || 0}/{leadPagination.totalrecords || 0} entries on Page {leadPagination.page_no || 0}/{leadPagination.totalpage || 0}
              </p>
            ) : (<></>)}
            </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeadListFilter;
