import Formsy from "formsy-react";
import React, { useEffect, useRef, useState } from "react";
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import FormsySelect from "../../elements/FormsySelect";
import Modal from "../../elements/Modal";
import Loader from "../../elements/Loader";
import { RotatingLines } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Tab, Nav } from "react-bootstrap";
import dateFormat from "dateformat";
import { getLoanDetail } from "../../../store/action/allAction";
import {
  CHECK_CIBIL_CONSIGNMENT,
  GET_CIBIL_DETAILS,
  GET_DETAILS_BY_PANCARD_UPDATE,
  SEND_CONSIGNMENT_LINK,
  GET_CIBIL_INFO,
  GENERATE_CIBIL_PDF,
  GET_LAST_CIBIL_INFO,
} from "../../../services/customer.gql";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { useLocation } from "react-router-dom";
import BlankCibilReport from "./BlankCibilReport";
import CibilReportDetail from "./CibilReportDetail"; 
import CibilCrif from "./CibilCrif";

const CIBIL_REPORT_FIELDS = FormFields["CIBIL_REPORT"];
const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date().setFullYear(new Date().getFullYear() - 76);

const CibilReport = () => {
  const client = useApolloClient();
  const location = useLocation();
  const dispatch = useDispatch();
  const [cibilDetails, setCibilDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false); 
  const [cibilStatus, setCibilStatus] = useState(null);
  const [refersh, setRefresh] = useState(0);
  const [cibilCheckModal, setCibilCheckModal] = useState(false);
  const [isMobileFetched, setIsMobileFetched] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [userList, setUserList] = useState([false]);
  const [userListMaster, setUserListMaster] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchMobileBtnDisabled, setFetchMobileBtnDisabled] = useState(false);
  const [resendLinkBtnDisabled, setResendLinkBtnDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState("customer");
  const user_information = localStorage.getItem("user_information");
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  const { lead } = useSelector(({ lead }) => ({ lead })); 
  const [storePanCard,setStorePanCard] = useState([]); 
  const [pageLoad , setPageLoad] = useState(false);
 
  const activeDetails = userList.filter(ob=>ob.type ==activeTab)?.[0] || {}  
  
  useEffect(()=>{ 
    let options = [
      { 
        id: lead?.leadDetail?.customer?.customer_id,
        label: lead?.leadDetail?.customer?.first_name +' '+lead?.leadDetail?.customer?.last_name,
        value: lead?.leadDetail?.customer?.customer_id,
        first_name: lead?.leadDetail?.customer?.first_name ,
        last_name: lead?.leadDetail?.customer?.last_name,
        dob: dateFormat(lead?.leadDetail?.customer?.dob, "dd mmm, yyyy"), 
        type:'customer' ,
        cibil_status : lead?.leadDetail?.customer?.cibil_status,
        pan_card: lead?.leadDetail?.customer?.pancard_no,
        email: lead?.leadDetail?.customer?.email,
        mobile: lead?.leadDetail?.customer?.mobile
      },
    ]  
    for(let coList of lead?.leadDetail?.co_applicant){ 
      options.push(
        { 
          id: coList.id,
          label: coList.ca_first_name+' '+ coList.ca_last_name +' co-applicant', 
          value: coList.id,type:'co-'+coList.id,
          first_name: coList.ca_first_name ,
          last_name: coList.ca_last_name,
          dob:  dateFormat(coList.ca_dob, "dd mmm, yyyy") ,  
          cibil_status : coList?.cibil_status,
          pan_card: coList?.ca_pancard_no,
          email: coList?.ca_email,
          mobile: coList?.ca_mobile

        }
      )
    }
    setUserList(options); 
    setUserListMaster(options)
  },[lead?.leadDetail?.customer?.pancard_no,lead?.leadDetail?.customer?.mobile])
  

  useEffect(() => {
    if (lead && lead?.leadDetail && lead?.leadDetail?.customer) {
    const currentPath = location.pathname.trim().split('/');
    if(currentPath.length && currentPath[3] === 'cibil-report' && currentPath[2] === 'customer-details') {
      const existingCibil = lead.leadDetail.lead_details?.cibil_score;
      // setCibilCheckModal(true);
      // if (existingCibil === null || existingCibil === "") {
      //   setCibilCheckModal(true);
      // }
    //  getLastCibilReport(lead.leadDetail.customer) 
      checkConsignment(true); 
      setActiveTab('customer')
      /* setCibilDetails((current) => ({
        ...current,
        pancard_no: lead.leadDetail.customer?.pancard_no,
        credit_score: 0,
        // credit_score: lead.leadDetail.lead_details.cibil_score || 0,
        first_name: lead.leadDetail.customer?.first_name,
        last_name: lead.leadDetail.customer?.last_name,
        mobile: lead.leadDetail.customer?.mobile,
        gender: lead.leadDetail.customer?.gender,
      })); */
      }
    }
  }, [lead?.leadDetail.id, location]);

  const checkConsignment = async (isLoad=false)=>{ 
    setLoading(true) 
 
    const data = {
      pan_card:  lead?.leadDetail?.customer?.pancard_no,
      mobile: lead?.leadDetail?.customer?.mobile,
      partner_id: `${lead?.leadDetail?.partner_id}`
    }; 
    if( data.pan_card){
      const exConsignResp = await callCheckConsignment({
        pan_card: data.pan_card,
        mobile: data.mobile,
        partner_id: data.partner_id,
        is_load: isLoad ? 1:0
      }); 
      setLoading(false)   
      if (exConsignResp.status === 200 && !exConsignResp?.data?.error_message?.includes('Due to some issue') &&  ['try_again','resend'].includes(exConsignResp.data?.cta) ) {  
        setCibilDetails((current) => ({
          ...current,
          cibilErrorMessage: exConsignResp.data?.cta,
          cta_error:exConsignResp.data?.error_message
        })); 
        setCibilStatus(exConsignResp.data?.cta) 
      } else{  
        getStoreCibilReport(lead.leadDetail.customer);  
      }  
    }else{
      setPageLoad(true)
      //setCibilCheckModal(true);
      setLoading(false) 
    } 
  } 

  const getOldMobile = ()=>{ 
    let mobile= lead?.leadDetail?.customer?.mobile; 
    if(activeDetails.type !='customer'){
      mobile = lead?.leadDetail?.co_applicant.filter(ob=>ob.id ==activeDetails.id)?.[0]?.ca_mobile || null; 
    }
    return mobile; 
  }

  const getColor = (value) => {
    if (value <= 400) {
      return "red";
    } else if (value <= 700) {
      return "yellow";
    } else {
      return "blue";
    }
  };

  const getText = (value) => {
    value = value.toString();
    let cibilvalue = value?.split("-")?.[1] || value?.split("-")?.[0] || 0;
    if (cibilvalue <= 400) {
      return "Low";
    } else if (cibilvalue <= 700) {
      return "Medium";
    } else {
      return "Very Good";
    }
  };

  const handleModalClose = (event) => {
    if (
      !cibilDetails["credit_score"] ||
      cibilDetails["credit_score"] === "" ||
      cibilDetails["credit_score"] === 0
    ) {
      setCibilDetails((current) => ({
        ...current,
        cibilErrorMessage: (!['try_again','edit'].includes(cibilDetails["cibilErrorMessage"]))? "Consent not received" : cibilDetails["cibilErrorMessage"],
      }));
    } 
    setCibilCheckModal(false);
  };

  const handlePanChange = (event) => {
    const { id, value } = event.target;
    if (id === "pancard_no") setFetchMobileBtnDisabled(false);
    setCibilDetails((current) => ({
      ...current,
      [id]: value.toUpperCase(),
    }));
  };

  const callCheckConsignment = async (data) => {
    try {
      const variables = {
        checkConsignmentInput: data,
        user_id: user_id,
        lead_id: lead?.leadDetail?.id
      };
      const response = await executeGraphQLMutation(
        CHECK_CIBIL_CONSIGNMENT,
        variables,
        client
      );
      if (response.data?.check_cibil_consignment) {
        return JSON.parse(response.data?.check_cibil_consignment);
      } else { 
        throw Error("Internal server error");
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      return {
        status: 500,
        message: error.message,
      };
    }
  };

  const callSendConsignmentLink = async (data) => {
    try {
      const variables = {
        sendConsignmentLinkInput: data,
      };
      const response = await executeGraphQLMutation(
        SEND_CONSIGNMENT_LINK(user_id, lead?.leadDetail?.id),
        variables,
        client
      );
      if (response.data?.send_consignment_link) {
        return JSON.parse(response.data?.send_consignment_link);
      } else {
       // console.log(response.data);
        throw Error("Internal server error");
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      return {
        status: 500,
        message: error.message,
      };
    }
  };
  const callGetCibilDetails = async (data,type,id) => {
    try {
      const variables = {
        cibilDetailsInput: data,
        type,
        id
      };
      const response = await executeGraphQLMutation(
        GET_CIBIL_DETAILS,
        variables,
        client
      );
      if (response.data?.get_cibil_details) {
        dispatch(getLoanDetail(lead?.leadDetail?.id, client))
        if (data.is_consigned == 1) {
          setTimeout(async () => {
            const result = await executeGraphQLQuery(
              GET_LAST_CIBIL_INFO(data.pan_card),
              client
            );
            if (result?.data?.last_cibil_info?.data) {
              setCibilDetails((current) => ({
                ...current,
                cibilErrorMessage: null,
                ...result?.data?.last_cibil_info?.data,
                last_update_date: result?.data?.last_cibil_info.created_date,
              }));
            }
          }, 100);
        }
        return JSON.parse(response.data?.get_cibil_details);
      } else {
        //console.log(response.data);
        throw Error("Internal server error");
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      return {
        status: 500,
        message: error.message,
      };
    }
  };

  const cibilProcess = async (data,type,id) => {
    const getCibilResp = await callGetCibilDetails(data,type,id);
    if (getCibilResp.status === 200) {
      const consignLinkResp = await callSendConsignmentLink({
        pan_card: data.pan_card,
        mobile: data.mobile,
        partner_id: data.partner_id,
      });

      if (consignLinkResp.status === 200) {
        setCibilDetails((current) => ({
          ...current,
          cibilErrorMessage: "Consent not received",
        }));
        setTimeout(() => {
          setIsLoaderVisible(false);
          setCibilCheckModal(false);
          toast.success("Please come back after consent");
        }, 5000);
      }
    } else {
      setCibilDetails((current) => ({
        ...current,
        cibilErrorMessage: "Something went wrong!. Please try after some time",
      }));
      setTimeout(() => {
        setIsLoaderVisible(false);
        setCibilCheckModal(false);
      }, 2000);
      toast.error("Internal Server Error");
    }
  };

  const handleFetchMobile = async () => {
    try {
      setFetchMobileBtnDisabled(true);
      const pan_card =  activeDetails.pan_card;
      let customer_type = 'customer';
      let customer_id = lead?.leadDetail?.customer?.customer_id;
      if (activeTab != "customer") {
        customer_type = "coapplicant";
        customer_id = activeTab?.split("-")?.[1] || 0;
      }
      const result = await executeGraphQLQuery(
        GET_DETAILS_BY_PANCARD_UPDATE(pan_card, customer_type, customer_id),
        client
      );
      if (result.data) {
        const jsonResp = JSON.parse(result.data?.get_details_by_pancard); 
        dispatch(getLoanDetail(lead?.leadDetail?.id, client))
        let apiResponse = jsonResp.data?.response || {};
        if (apiResponse?.code === 200) {   
          let data = userList;
          let splitDob = apiResponse?.dob.split('/');
          let dobFormat =  splitDob[2]+"-"+splitDob[1]+"-"+splitDob[0]; 
          for(let item of data){
            if(item.type ==activeTab){
              item['dob'] = dateFormat(new Date(dobFormat) , "dd mmm, yyyy")
              item['first_name'] = apiResponse?.firstName
              item['last_name'] = apiResponse?.lastName
              item['mobile'] = apiResponse?.mobile_no
            }
          }
          setUserList(data); 
          setCibilDetails((current) => ({
            ...current,
            mobile: apiResponse?.mobile_no || '',
            first_name: apiResponse?.firstName || apiResponse?.name,
            middle_name: apiResponse?.middleName,
            last_name: apiResponse?.lastName,
            dob: apiResponse?.dob,
            gender: apiResponse?.gender,
            pancard_no: pan_card
          }));
          setIsMobileFetched(true);
        } else {
          setIsMobileFetched(false);
          toast.error(apiResponse?.message);
        }
      } else {
        toast.error("Internal Server Error");
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      toast.error(error.message);
    }
  };

  const generatePdf = async () => {
    try {
      setLoading(true);
      const pan_card =  activeDetails.pan_card
      const result = await executeGraphQLQuery(
        GENERATE_CIBIL_PDF(pan_card, 1, user_id, lead?.leadDetail?.id),
        client
      );
      if (result.data) {
        setLoading(false);
        const record = result.data?.generate_cibil_report?.storePath;
        window.open(record, "_blank");
      } else {
        setLoading(false);
        toast.error("Internal Server Error");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }; 
  const handleFetchCibilReport = async (isdetail=false) => {
    try {  
 
      setLoading(true)
      setIsLoaderVisible(true);
      // const data = {
      //   pan_card: cibilDetails["pancard_no"],
      //   first_name: cibilDetails["first_name"],
      //   middle_name: cibilDetails["middle_name"],
      //   last_name: cibilDetails["last_name"],
      //   dob: cibilDetails["dob"],
      //   gender: cibilDetails["gender"],
      //   mobile: cibilDetails["mobile"],
      //   partner_id: '12327', //`${lead?.leadDetail?.partner_id}`,
      //   user_id,
      //   partner_name: 'KISHORE. THAKARE', //lead?.leadDetail?.partner_name,
      //   is_consigned: 0,
      // };
      const data = {
        pan_card: activeDetails.pan_card,
        first_name: activeDetails?.["first_name"],
        middle_name: '',
        last_name: activeDetails?.["last_name"],
        dob:  dateFormat(activeDetails?.["dob"], "dd/mm/yyyy"),
        gender: cibilDetails["gender"] || lead?.leadDetail?.customer?.gender,
        mobile: activeDetails?.["mobile"]|| lead?.leadDetail?.customer?.mobile,
        partner_id: `${lead?.leadDetail?.partner_id}`,
        user_id,
        partner_name: lead?.leadDetail?.partner_name || authUser?.name,
        is_consigned: 0,
        lead_id: lead?.leadDetail.id,
        lead_type: lead?.leadDetail?.fulfillment_type =='ambak'?1:0,
        update_concent: 0
      }; 
      if(1==1){
        data.update_concent = 1;
        data.old_mobile = getOldMobile() 

        cibilProcess(data,activeDetails?.type,activeDetails?.id);
        setLoading(false) 
        // const getCibilResp = await callGetCibilDetails(data,activeDetails?.type,activeDetails?.id); 
        // setTimeout(() => {
        //   setIsLoaderVisible(false);
        //   setCibilCheckModal(false);
        // }, 2000);
        // if (getCibilResp.status === 200) {
        //   setLoading(false) 
        // } else {
        //   setCibilDetails((current) => ({
        //     ...current,
        //     cibilErrorMessage: "Something went wrong!. Please try after some time",
        //   }));
        //   toast.error("Internal Server Error");
        // }
 
      }else if(isdetail){ 
        const cibilResp = await callGetCibilDetails({ ...data, is_consigned: 1 },activeDetails?.type,activeDetails?.id);
        if (cibilResp.status === 200) {
          setCibilDetails({ 
            cibilErrorMessage: null,
            pancard_no: lead.leadDetail.customer?.pancard_no,
            credit_score: 0, 
            first_name: lead.leadDetail.customer?.first_name,
            last_name: lead.leadDetail.customer?.last_name,
            mobile: lead.leadDetail.customer?.mobile,
            gender: lead.leadDetail.customer?.gender,
            ...cibilResp?.data
          }) 
        }
        
        setLoading(false) 
      } else{ 

        const exConsignResp = await callCheckConsignment({
          pan_card: data.pan_card,
          mobile: data.mobile,
          partner_id: data.partner_id,
        }); 
        setLoading(false) 
        if (exConsignResp.status === 200) {
          if (['try_again','resend'].includes(exConsignResp.data?.cta) ) {
            setCibilDetails((current) => ({
              ...current,
              cibilErrorMessage: exConsignResp.data?.cta,
            }));
            setTimeout(() => {
              setIsLoaderVisible(false);
              setCibilCheckModal(false);
            }, 2000);
          }else if (exConsignResp.data?.consign_status === 1) {
            const cibilResp = await callGetCibilDetails({
              ...data,
              is_consigned: 1
            },activeDetails?.type,activeDetails?.id);
            setTimeout(() => {
              setIsLoaderVisible(false);
              setCibilCheckModal(false);
            }, 2000);
            if (cibilResp.status === 200) {
              setCibilDetails({ 
                cibilErrorMessage: null,
                pancard_no: lead.leadDetail.customer?.pancard_no,
                credit_score: 0, 
                first_name: lead.leadDetail.customer?.first_name,
                last_name: lead.leadDetail.customer?.last_name,
                mobile: lead.leadDetail.customer?.mobile,
                gender: lead.leadDetail.customer?.gender,
                ...cibilResp?.data
              }) 
            } else if (cibilResp.status === 400) {
            //  console.log(cibilResp.errors);
              toast.error(`${cibilResp.message}`);
              setCibilDetails((current) => ({
                ...current,
                cibilErrorMessage: cibilResp.errors?.error || "Invalid details",
              })); 
            }
          } else {
            toast.error("Please give consent using sent link");
            setCibilDetails((current) => ({
              ...current,
              cibilErrorMessage: "Consent not received",
            })); 
          }
        } else if(exConsignResp.status === 400){
            setCibilDetails((current) => ({
              ...current,
              cibilErrorMessage: 'resend',
            }));
            setTimeout(() => {
              setIsLoaderVisible(false);
              setCibilCheckModal(false);
            }, 2000);
        }  else {
          cibilProcess(data,activeDetails?.type,activeDetails?.id); 
        }
        setIsLoaderVisible(false);
        setCibilCheckModal(false);
      } 
     
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      toast.error(error.message);
      setCibilDetails((current) => ({
        ...current,
        cibilErrorMessage: "Something went wrong!. Please try after some time",
      }));
      setTimeout(() => {
        setIsLoaderVisible(false);
        setCibilCheckModal(false);
      }, 2000);
    }
  };

  const handleResendLink = () => {
    if (
      cibilDetails["pancard_no"] &&
      cibilDetails["first_name"] &&
      cibilDetails["mobile"] &&
      cibilDetails["dob"]
    ) { 
       const data = {
        pan_card: activeDetails.pan_card,
        first_name: activeDetails?.["first_name"],
        middle_name: '',
        last_name: activeDetails?.["last_name"],
        dob: dateFormat(activeDetails?.["dob"], "dd/mm/yyyy"),
        gender: cibilDetails["gender"],
        mobile: activeDetails?.["mobile"],
        partner_id: `${lead?.leadDetail?.partner_id}` || authUser?.name,
        user_id,
        partner_name: lead?.leadDetail?.partner_name || authUser?.name ,
        is_consigned: 0,
      };
      setCibilCheckModal(true);
      setIsLoaderVisible(true);
      setResendLinkBtnDisabled(true);
      cibilProcess(data,activeDetails?.type,activeDetails?.id);
    } else {
      window.location.reload();
    }
  };

  /*   useEffect(()=>{
    getCibilReport('BWNPK3502M')
  },[])
  const getCibilReport =  async (pan_card)=>{
    let report_type = 'report_summary,enquiry_details,loan_accounts,credit_utilization_details,payment_history,payment_history_details,credit_age'
    const result = await executeGraphQLQuery(
      GET_CIBIL_INFO(pan_card,report_type),
      client
    );
    if(result?.data?.cibil_info?.data) {
      setCibilRecord(result?.data?.cibil_info?.data) 
    }
  }  */ 
    const getStoreCibilReport =  async (reqData)=>{ 
      let pan_card = reqData?.pancard_no;  
      const result = await executeGraphQLQuery(GET_LAST_CIBIL_INFO(pan_card),client);
        if(result?.data?.last_cibil_info?.data) { 
          setCibilCheckModal(false); 
          setStorePanCard([...storePanCard,reqData?.pancard_no])
          setCibilDetails((current) => ({
            ...current,
            cibilErrorMessage: null,
            ...result?.data?.last_cibil_info?.data,
            last_update_date:result?.data?.last_cibil_info.created_date,
            pancard_no: reqData?.pancard_no,
            credit_score: 0, 
            first_name: reqData?.first_name,
            last_name: reqData?.last_name,
            mobile: reqData?.mobile,
            gender: reqData?.gender,
          })); 
        }else{
          // setCibilCheckModal(true); // Commented AMB-834 as we have 2 types of CIBIL Fetch system
          setCibilDetails((current) => ({
            ...current,
            pancard_no: lead.leadDetail.customer?.pancard_no,
            cibilErrorMessage:" "
          }));
        } 
    }  
  const getLastCibilReport =  async (reqData)=>{ 
      let pan_card = reqData?.pancard_no; 
      const data = {
        pan_card: cibilDetails["pancard_no"], 
        mobile: cibilDetails["mobile"],
        partner_id: `${lead?.leadDetail?.partner_id}`
      }; 
      const exConsignResp = await callCheckConsignment({
        pan_card: data.pan_card,
        mobile: data.mobile,
        partner_id: data.partner_id,
      });  
      if (exConsignResp.status === 200 && ['try_again','resend'].includes(exConsignResp.data?.cta) ) {   
        setCibilDetails((current) => ({
          ...current,
          cibilErrorMessage: exConsignResp.data?.cta,
        })); 
        setCibilStatus(exConsignResp.data?.cta)  
      }else{ 
        const result = await executeGraphQLQuery(GET_LAST_CIBIL_INFO(pan_card),client);
        if(result?.data?.last_cibil_info?.data) { 
          setCibilCheckModal(false);
  
          setCibilDetails((current) => ({
            ...current,
            cibilErrorMessage: null,
            ...result?.data?.last_cibil_info?.data,
            last_update_date:result?.data?.last_cibil_info.created_date,
            pancard_no: reqData?.pancard_no,
            credit_score: 0, 
            first_name: reqData?.first_name,
            last_name: reqData?.last_name,
            mobile: reqData?.mobile,
            gender: reqData?.gender,
          })); 
        }else{
          setCibilDetails((current) => ({
            ...current, 
            pancard_no: reqData?.pancard_no,
            credit_score: 0, 
            first_name: reqData?.first_name,
            last_name: reqData?.last_name,
            mobile: reqData?.mobile,
            gender: reqData?.gender,
          })); 
          if(cibilStatus){
            setIsMobileFetched(true)
            setCibilDetails((current) => ({
              ...current,
              cibilErrorMessage: cibilStatus,
            }));
          }else{
             setCibilCheckModal(true);
          }  
        }
      }   

    }  
  const handleSelectTab = (eventKey) => {  
    setPageLoad(true)
    if (eventKey == "customer") {
      getStoreCibilReport(lead.leadDetail.customer);   
    } else {
      let coId = eventKey?.split("-")?.[1] || 0;
      let co_applicant =
        lead?.leadDetail?.co_applicant.filter((ob) => ob.id == coId)?.[0] || {};
      let coRecord = {
        pancard_no: co_applicant?.ca_pancard_no || "",
        first_name: co_applicant?.ca_first_name || "",
        last_name: co_applicant?.ca_last_name || "",
        mobile: co_applicant?.ca_mobile || "",
        gender: co_applicant?.gender || "",
      }; 
      getStoreCibilReport(coRecord);   
    }
    setActiveTab(eventKey);
  };

  const handleSelectChange = (data) => {
    if (data.type == "customer") {
      let reqData = lead?.leadDetail?.customer;
      setCibilDetails((current) => ({
        ...current,
        pancard_no: reqData?.pancard_no,
        first_name: reqData?.first_name,
        last_name: reqData?.last_name,
        mobile: reqData?.mobile,
        gender: reqData?.gender,
      }));
    } else {
      let coId = data.type?.split("-")?.[1] || 0;
      let co_applicant =
        lead?.leadDetail?.co_applicant.filter((ob) => ob.id == coId)?.[0] || {};
      let reqData = {
        pancard_no: co_applicant?.ca_pancard_no || "",
        first_name: co_applicant?.ca_first_name || "",
        last_name: co_applicant?.ca_last_name || "",
        mobile: co_applicant?.ca_mobile || "",
        gender: co_applicant?.gender || "",
      };
      setCibilDetails((current) => ({
        ...current,
        pancard_no: reqData?.pancard_no,
        first_name: reqData?.first_name,
        last_name: reqData?.last_name,
        mobile: reqData?.mobile,
        gender: reqData?.gender,
      }));
    }
    setActiveTab(data.type);   
  }  
  const changeDetail = (e)=>{
    let data = userList;
    for(let item of data){
      if(item.type ==activeTab){
        item[e.target.name] =  e.target.value
      }
    }
    setUserList(data); 
  } 
  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      let data = userList;
      for(let item of data){
        if(item.type ==activeTab){ 
          item[field_name] =  dateFormat(date, "dd mmm, yyyy")
        }
      }
      setUserList(data);  
      setRefresh(refersh+1)
    }
  }; 

  const editInformation = ()=>{
    setCibilCheckModal(true)
    handleFetchMobile();
  }  

  const editPanCard = ()=>{ 
    setIsMobileFetched(false)
    setFetchMobileBtnDisabled(false)
  }  
  return (
    <div className="image-form-outer"> 
      {loading ? <Loader/> : null}
      {/* CIBIL SCORE MODAL */}
      {cibilCheckModal ? (
        <>
          <div className="mark-lost-popup">
            <Modal
              show={cibilCheckModal}
              id="cibilCheckModal"
              handleClose={handleModalClose}
              removeHeight={true}
            >
              <div className="modal-header">
                <h2>Check Cibil Score</h2>
              </div>
              <div className="modal-body">
                {isLoaderVisible ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <RotatingLines
                        visible={true}
                        height="80"
                        width="80"
                        strokeColor="grey"
                        strokeWidth="4"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                      <p>Waiting for customer's consent.</p>
                      <p>
                        A consent link has been shared with customer via SMS.
                      </p>
                      <p>
                        Please ask them to open the link and confirm enter the
                        OTP for confirmation.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <Formsy className="lead-form-filed" autoComplete="off" 
                    onValid={() => setAllowSubmit(true)}
                    onInvalid={() => setAllowSubmit(false)}
                    >
                      {/* <fieldset className="single-select col-md-12 ">
                        <FormsySelect
                          name={"user_type"}
                          inputProps={{
                            options: userList.filter(ob=>!storePanCard.includes(ob.pan_card)),
                            placeholder: "select field",
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: userList.filter(
                              ({ type }) => type == activeTab
                            ),
                          }}
                          required={false}
                          // value={basicDetails && basicDetails[field.name]}
                          onChange={(data) => handleSelectChange(data)}
                        />
                      </fieldset> */}
                      <fieldset className="form-filed col-md-12 ">
                        <FormsyInputField
                          id="pan_card"
                          name="pan_card"
                          type="text"
                          value={activeDetails?.["pan_card"] || ""}
                          placeholder=" "
                          maxLength="10"
                          label="Pan Card No."
                          onChange={changeDetail}
                          format={true}
                          validations={"isValidPanCard"}
                          validationError={"Pancard is required"}
                          //readOnly={isMobileFetched ? false: true}
                          required={true}
                        />
                        {
                          isMobileFetched ?  <span style={{position:"relative",zIndex:1,display:"flex",justifyContent:"end",margin:"-32px 10px 0px 0px",cursor:"pointer",color:"blue"}} onClick={editPanCard}>Edit Pan</span> : 
                          null
                        }
                        
                      </fieldset>
                      {isMobileFetched && (
                        <>
                          <fieldset className="form-filed col-md-12 ">
                            <FormsyInputField
                              id="mobile"
                              name="mobile"
                              type="text"
                              value={activeDetails?.["mobile"] || ""}
                              placeholder=" "
                              maxLength="10"
                              label="Mobile No."
                              onChange={changeDetail}
                              format={true}
                              validations={"isValidPhoneNumber"}
                              validationError={"Mobile No is required"}
                              readOnly={false}
                              required={true}
                            />
                          </fieldset>
                          <fieldset className="form-filed col-md-12 ">
                            <FormsyInputField 
                              name="first_name"
                              type="text"
                              value={activeDetails?.first_name || ""}
                              placeholder=" " 
                              label="First Name"
                              onChange={changeDetail}
                              format={true} 
                              validationError={"First Name is required"}
                              readOnly={false}
                              required={true}
                            />
                          </fieldset>
                          <fieldset className="form-filed col-md-12 ">
                            <FormsyInputField 
                              name="last_name"
                              type="text"
                              value={activeDetails?.last_name || ""}
                              placeholder=" " 
                              label="Last Name"
                              onChange={changeDetail}
                              format={true} 
                              validationError={""}
                              readOnly={false}
                              required={false}
                            />
                          </fieldset>
                          <fieldset className="form-filed col-md-12 ">
                           <FormsyDatePicker
                              name="dob"
                              selectedDate={ activeDetails.dob && new Date(activeDetails.dob)}
                              placeholder={'DOB'}
                              maxDate={DOB_MAX_DATE}
                              minDate={DOB_MIN_DATE}
                              value={activeDetails?.dob}
                              onDateSelect={(date) =>
                                handleDateChange(date, 'dob')
                              }
                              dateFormat="dd MMM, yyyy"
                              validations={null}
                              validationError={"DOB is required"}
                              required={true}
                              hasError={false}
                            /> 
                          </fieldset>
                        </>
                      )}
                      <div className="btn-save-remarks">
                        {isMobileFetched ? (
                          <button
                            type="button"
                            className="btn-primary"
                            onClick={()=>handleFetchCibilReport(false)}
                            disabled={!allowSubmit}
                          >
                            Fetch Cibil Report
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn-primary"
                            onClick={handleFetchMobile}
                            disabled={fetchMobileBtnDisabled}
                          >
                            Fetch mobile no.
                          </button>
                        )}
                      </div>
                    </Formsy>
                  </>
                )}
              </div>
            </Modal>
          </div>
        </>
      ) : (!cibilDetails?.last_update_date || cibilDetails?.cibilErrorMessage) ? (
        <BlankCibilReport
          cibilErrorMessage={cibilDetails["cibilErrorMessage"]}
          resendConsignmentLink={handleResendLink}
          resendConsignmentBtn={resendLinkBtnDisabled}
          handleSelectTab={handleSelectTab}
          activeTab={activeTab}
          lead={lead}
          editInformation={editInformation}
          handleFetchCibilReport={handleFetchCibilReport}
          cta_error={cibilDetails["cta_error"]}
          userListMaster={userListMaster}
          pageLoad={pageLoad}
          setCibilCheckModal={setCibilCheckModal}
        />
      ) : (
        <>
          {/* LEFT PANEL */}
          <div className="image-tab-panel form-tab-right-panel">
            <div>Cibil Report</div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => null}
              onInvalid={() => null}
            >
              <div className="row">
                {CIBIL_REPORT_FIELDS.map((field, index) =>
                  ["text", "pattern-format", "number"].includes(field.type) ? (
                    <fieldset className="form-filed col-md-12" key={index}>
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={cibilDetails[field.name] || ""}
                        placeholder=" "
                        label={field.label}
                        format={field.ApplyNumberformat ? field.format : ""}
                        readOnly={field.readOnly}
                        required={field.required}
                      />
                    </fieldset>
                  ) : field.type === "date-picker" ? (
                    <fieldset className="form-filed col-md-12" key={index}>
                      <FormsyDatePicker
                        name="login_date"
                        selectedDate={
                          cibilDetails &&
                          cibilDetails[field.name] &&
                          new Date(cibilDetails[field.name])
                        }
                        placeholder={field.label}
                        maxDate={field.maxDate}
                        minDate={field.minDate}
                        value={(cibilDetails && cibilDetails[field.name]) || ""}
                        dateFormat="dd MMM, yyyy"
                      />
                    </fieldset>
                  ) : null
                )}
              </div>
            </Formsy>
          </div>

          {/* MIDDLE PANEL */}
          <div className="form-tab-right-panel">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={activeTab}
              onSelect={handleSelectTab}
            >
              <Nav variant="pills" className="flex-column tab-line">
                <div className="tab-list">
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      active={activeTab == "customer"}
                      eventKey={"customer"}
                      disabled={false}
                    >
                      {" "}
                      Customer
                    </Nav.Link>
                  </Nav.Item>
                  {lead?.leadDetail.co_applicant?.length
                    ? lead?.leadDetail.co_applicant.map((ob, key) => {
                        return (
                          <Nav.Item className="nav-item" key={key}>
                            <Nav.Link
                              active={activeTab == "co-" + ob.id}
                              eventKey={"co-" + ob.id}
                            >
                              {ob.ca_first_name} co applicant
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })
                    : null}
                </div>
              </Nav>
            </Tab.Container>
            <div style={{ marginTop: 40 }}>
              <div className="cibilnewcard">
                <div>
                  <div>
                    <div>
                      Name : <span>{cibilDetails?.report_summary?.name}</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      Mobile Number: +91<span>{cibilDetails?.mobile}</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      Email ID :{" "}
                      <span>{activeDetails?.email || 'NA'}</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      PAN Card : <span>{cibilDetails?.pancard_no}</span>
                    </div>
                  </div>
                </div>

                <div class="cibilcircle">
                  <img
                    src="https://ambak.storage.googleapis.com/partner/2123/1724145263610.png"
                    alt="circle"
                    width="180"
                    height="160"
                  />
                  <div class="textoverlay">
                    <div className="cibilscoreheading">
                      {" "}
                      {cibilDetails?.mobile_res?.credit_score || -1}
                    </div>
                    <div className="cibilsubstatus">
                      {" "}
                      {cibilDetails?.mobile_res?.["cibil_label"]}{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="cibilscorebox">
                <ReactStoreIndicator
                  width={300}
                  value={700}
                  minValue={300}
                  maxValue={900}
                  lineWidth={20}
                  lineGap={0}
                />
                <div className="cibilscore">
                  <div
                    style={{
                      fontSize: 40,
                      fontWeight: 600,
                      color: getColor(cibilDetails?.mobile_res?.cibil_score || 0),
                    }}
                  >
                    {cibilDetails?.mobile_res?.credit_score || -1}
                  </div>
                  <div
                    style={{
                      fontSize: 18,
                      color: getColor(cibilDetails?.mobile_res?.cibil_score || 0),
                    }}
                  >
                    {cibilDetails?.mobile_res?.["cibil_label"]} 
                  </div>
                </div>
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <span style={{ marginRight: 200 }}>{300}</span>{" "}
                  <span>{900}</span>
                </div>
              </div> */}
              <div className="cibilupdated">
                <span>Last Updated : </span>
                <span>
                  {cibilDetails["last_update_date"]
                    ? dateFormat(
                        cibilDetails["last_update_date"],
                        "d mmm,yyyy hh:MM TT"
                      )
                    : "N/A"}
                </span>
              </div>
              {!!cibilDetails?.last_update_date && (
                <div class="btn-save-remarks ctrs">
                  <button type="submit" class="btn-primary" onClick={generatePdf}>
                    Download Report
                  </button>
                </div>
              )}
            </div>
            <CibilCrif lead={lead} activeTab={activeTab} userListMaster={userListMaster}/>
          </div>

          {/* RIGHT PANEL */}
          <CibilReportDetail
            cibilDetails={cibilDetails}
            cibilRecord={cibilDetails}
          />
        </>
      )}
    </div>
  );
};
export default CibilReport;
