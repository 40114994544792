import React, { useEffect, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/partnerExecuteGraphQLQuery.js";
import {
  EXPORT_CSV_PENDING_PAYMENT,
  FILE_URL_UPLOAD,
  EXPORT_CSV_ELIGIBLE,
  FILE_JSON_URL_UPLOAD,
  EXPORT_CSV_GST_PENDING,
  FILE_GST_URL_UPLOAD,
  EXPORT_CSV_PRE_ELIGIBLE,
  RE_CALCULATE_PRE_ELIGIBLE_LEADS,
} from "../../services/payout.gql.js";
import { toast } from "react-toastify";
import secureStorage from "./../../config/encrypt";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Loader from "../elements/Loader.js";
import {
  SCHEME_LIST,
  FINEX_MASTER_LIST,
  FINEX_BANK_LIST,
} from "../../services/scheme.gql";
import TableContent from "./TableContent.js";
import { InventoryService } from "../../services/index.js";
import {
  IS_USER_ACCESS_PAGE
} from "../../config/constants";
import BucketTabs from "../elements/BucketTabs.js";
import AddNotesPopup from "./component/AddNotesPopup.js";


const SearchContent = (props) => {
  const filterVariables = props.state;
  const buttonClick = props.buttonClick;
  const filterOptions = props.filterOptions;
  const filter_params = props.filter_params;
  const DataFIlter = props.filterData;
  const fileInputRef = useRef();
  const [url, setUrl] = useState();
  const [filterData, setFilterData] = useState({
    status: "calculated",
    source: "web",
  });
  const [pageloading, setpageloading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selected_partner_id, setSelectedPartnerID] = useState(0);
  const [schemeList, setschemeList] = useState([]);
  const [productType, setProductType] = useState([]);
  const [financers, setFinancers] = useState([]);
  const [fulfillmenttypes, setFulfillmentTypes] = useState([]);
  const [user_list, setUserList] = useState([]);
  const [financersBanks, setFinancersPersonal] = useState([]);
  const [counter, setCounter] = useState(0);
  const [csvFile, SetCsvFile] = useState();
  const [disableRecalculate, setDisableReCalculate] = useState(true)
  const [recalculateList, setReCalculateLeadList] = useState([])
  const [activeTab, setActiveTab] = useState("standard")
  const [openPopup, setOpenPopup] = useState(false)
  

  useEffect(()=>{
    setDisableReCalculate(!(recalculateList.length>0))
    setpageloading(false)
  },[recalculateList])

  let pre_eligible = 1
  let eligible = IS_USER_ACCESS_PAGE("payout", "eligible")?.is_edit_access;
  let approved_for_finance = IS_USER_ACCESS_PAGE(
    "payout",
    "approved_for_finance"
  )?.is_edit_access;

  let payment_pending = IS_USER_ACCESS_PAGE(
    "payout",
    "payment_pending"
  )?.is_edit_access;
//console.log("filter_params",filter_params)

  let paid_view = IS_USER_ACCESS_PAGE("payout", "paid_view")?.is_edit_access;

  let export_list = IS_USER_ACCESS_PAGE(
    "payout",
    "export_list"
  )?.is_edit_access;
  let upload_utr = IS_USER_ACCESS_PAGE("payout", "upload_utr")?.is_edit_access;
  let import_gst_json = IS_USER_ACCESS_PAGE(
    "payout",
    "import_gst_json"
  )?.is_edit_access;

  let gst_payment_pending = IS_USER_ACCESS_PAGE(
    "payout",
    "gst_payment_pending"
  )?.is_edit_access;

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Navigate to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    setpageloading(false);
    setSelectedPartnerID(loginResponse.data.user_data.user_id);
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;

    if (role != "dealercentral") {
      return <Navigate to={"/"} />;
    }
  };

  const handleStatusClick = (sname,currentTab) => {
    const filterDatanew = { ...filterData };

    if (sname == "all") {
      delete filterDatanew.status;
    } else {
      filterDatanew.status = sname;
    }
    setCounter((prevCounter) => prevCounter + 1);
    //console.log({filterDatanew})
    setFilterData(filterDatanew);

    // getleadsCounts();
    setSelectedRow(null);
    if(sname === "invoice_created"){
      setActiveTab("cndn")
    }else if(currentTab && currentTab==activeTab){
      setActiveTab("standard")
    }
  };

  useEffect(() => {
    getDealerList();
    getSchemeList();
    updateReduxData();
    // getleadsCounts();
    getFinexMasterData();
    getFinexPersonalBankData();
  }, []);

  const getSchemeList = async () => {
    const partnerArray = [];
    const schemeData = await executeGraphQLQuery(SCHEME_LIST);
    const data = schemeData.data.getAllSchemsList;
    data &&
      data.map((list) => {
        const partnerData = {
          label: list.id,
          value: list.scheme_name,
        };
        partnerArray.push(partnerData);
      });
    setschemeList(partnerArray);
  };

  // const getleadsCounts = async () => {
  //   const leadscounts = await executeGraphQLQuery(PAYOUT_LEADS_COUNTS);
  //   const data = leadscounts.data.leads_count;
  //   console.log("data", data);
  //   let total = 0;
  //   data &&
  //     data.map((list) => {
  //       console.log("list", list);
  //       total = total + parseInt(list.counts);
  //     });
  //   if (total > 0) {
  //     setTotalLeads(total);
  //   }
  //   setLeadsCounts(data);
  // };

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const producttypedata = [];
      const fulfillmenttypeData = [];
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancers(bankData);
      }
      if (product_type && product_type.length > 0) {
        product_type.map((ptype) => {
          producttypedata.push({ id: ptype.id, name: ptype.label });
        });
        setProductType(producttypedata);
      }
      const fulfillmenttype =
        data.fulfillment_type && data.fulfillment_type.length > 0
          ? data.fulfillment_type
          : [];
      if (fulfillmenttype && fulfillmenttype.length > 0) {
        fulfillmenttype.map((ptype) => {
          fulfillmenttypeData.push({ id: ptype.id, name: ptype.label });
        });
        setFulfillmentTypes(fulfillmenttypeData);
      }
    }
  };

  const getFinexPersonalBankData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_BANK_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.bank_pa) {
      const bankData = [];
      const data = masterData.data.bank_pa;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancersPersonal(bankData);
      }
    }
  };

  const getDealerList = () => {
    setUserList([]);
    // MasterService.get("account/user/user_list")
    //   .then(function (response) {
    //     if (response.data.status == 200) {
    //       if (response.data.data.length) {
    //         setUserList(response.data.data);
    //       } else {
    //         setUserList([]);
    //       }
    //     }
    //   })
    //   .catch(function (response) {});
  };

  let role;
  if (secureStorage.getItem("loginUserInfo")) {
    let loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
      role = loginResponse.data.user_data.role;
    }
  }

  const handleChange = async (e) => {
    try {
      
     // console.log("pppppppppppppppppppstatus",filterData.status)
      SetCsvFile(e.target.files[0]);
      let formData = new FormData();
      setpageloading(true)
      formData.append("dealer_id", selected_partner_id);
      formData.append("upload_type", "dealer_docs");
      formData.append("images", e.target.files[0]);
      const response = await InventoryService.uploadFilesToS3(formData, {
        "Content-Type": "multipart/form-data",
      });
      if (response.data.status === 200) {
        const file_url = response?.data?.data[0]?.file_url;
        const fileExt = response?.data?.data[0]?.file_name.split('.').pop();
        if(fileExt === 'csv'){
         if(filterData.status === "gst_payment_pending"){
               sendGstFile(file_url);
               setpageloading(false)
         } else {
               sendFileUrl(file_url);
               setpageloading(false)
         }
        } else {
              sendJsonFileUrl(file_url);
              setpageloading(false)
        }

      } else if (response.data.status === 400) {
        // toast.error("Failed");
      }
    } catch {
      // toast.error("Failed");
    }
  };

  const sendGstFile = (url) => {
    const variables = {
      uploadPaymentArgs: {
        csv_file: url,
        update_for: "gst",
      },
    };
    try {
     executeGraphQLMutation(FILE_GST_URL_UPLOAD, variables).then(result=>{
      const csvData = result?.data?.upload_gst_paid;
      if(!csvData) return;
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'gst_paid_data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      //toast.success(result?.data?.upload_gst ? result?.data?.upload_gst : "file uploaded succesfully");
     });
    } catch (error) {
      toast.error("Something went wrong!");
     
    }
  };

  const sendJsonFileUrl = (url) => {
    const variables = {
      uploadGstArgs: {
        json_file: url,
        update_for: "payment",
      },
    };
    try {
     executeGraphQLMutation(FILE_JSON_URL_UPLOAD, variables).then(result=>{
      const csvData = result?.data?.upload_gst;
      if(!csvData) return;
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'gst_pending_data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      //toast.success(result?.data?.upload_gst ? result?.data?.upload_gst : "file uploaded succesfully");
     });
    } catch (error) {
      toast.error("Something went wrong!");
     
    }
  };

  const sendFileUrl = (url) => {
    const variables = {
      uploadPaymentArgs: {
        csv_file: url,
        update_for: "payment",
      },
    };
    try {
     executeGraphQLMutation(FILE_URL_UPLOAD, variables).then(result=>{
     
      const csvData = result?.data?.upload_payment;
      if (!csvData) return;
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'payment_pending_data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      toast.success("file uploaded succesfully");
     });
    } catch (error) {
      toast.error("Something went wrong!");
     
    }
  };

  const downloadCsv = () => {
    const resultData = executeGraphQLQuery(EXPORT_CSV_PENDING_PAYMENT).then(
      (resultData) => {
        const csvData = resultData?.data?.export_payment_pending_invoice;
        if (!csvData) return;
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "payment_pending_data.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    );
  };
  
  const downloadCsvGstPending = () => {
    executeGraphQLQuery(EXPORT_CSV_GST_PENDING).then(
      (resultData) => {
        const csvData = resultData?.data?.export_gst_pending_invoice;
        if (!csvData) return;
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "gst_payment_pending_data.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    );
  };

  const downloadEligibleCsv = () => {
    const resultData = executeGraphQLQuery(EXPORT_CSV_ELIGIBLE).then(
      (resultData) => {
        const csvData = resultData?.data?.export_pending_invoice;
        if (!csvData) return;
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "export_eligible_data.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    );
  };

  const downloadPreEligibleCsv = () => {
    const resultData = executeGraphQLQuery(EXPORT_CSV_PRE_ELIGIBLE).then(
      (resultData) => {
        const csvData = resultData?.data?.export_pre_eligible_partners;
        if (!csvData) return;
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "export_pre_eligible_partners.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    );
  }
  const reCalcSelectedPreEligibleLeads = async () => {
    setpageloading(true)
      if (!window.confirm("Do you want to re-calculate these leads?")) {
          return false
      } else {
          try {
              const variables = {
                PreEligibleLeadArgs: {
                  leads: recalculateList,
                },
              };
              const result = await executeGraphQLMutation(RE_CALCULATE_PRE_ELIGIBLE_LEADS, variables);
              if (result?.data) {
                  toast.success(result?.data?.re_calculate_pre_eligible_leads)
              } else {
                  toast.error(result?.message || "Something went wrong!")
              }

          } catch (error) {
              toast.error("Failed");
          }
          setpageloading(false)
      }
  }
  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }
  const setPopup = (openVal) => {
    setOpenPopup(openVal)
  }
  return (
    <>
     {pageloading ? <Loader /> : null}
      <div className="pad-15">
        <div className="row">
          <div className="col-sm-8">
            <ul className="list-inline nav-tabs">
              {pre_eligible === 1 && (
                <li
                  className={
                    filterData.status == "calculated"
                      ? "tabs-item active"
                      : "tabs-item"
                  }
                  onClick={() => handleStatusClick("calculated",activeTab)}
                >
                  <div>{"Pre-Eligible"}</div>
                </li>
              )}

              {eligible === 1 && (
                <li
                  className={
                    filterData.status == "pending"
                      ? "tabs-item active"
                      : "tabs-item"
                  }
                  onClick={() => handleStatusClick("pending",activeTab)}
                >
                  <div>{"Eligible"}</div>
                </li>
              )}


              {payment_pending === 1 && (
                <li
                  className={
                    filterData.status == "payment_pending"
                      ? "tabs-item active"
                      : "tabs-item"
                  }
                  onClick={() => handleStatusClick("payment_pending",activeTab)}
                >
                  <div>{"Approved/Payment Pending"}</div>
                </li>
              )}

              {paid_view === 1 && (
                <li
                  className={
                    filterData.status == "paid"
                      ? "tabs-item active"
                      : "tabs-item"
                  }
                  onClick={() => handleStatusClick("paid",activeTab)}
                >
                  <div>{"Paid"}</div>
                </li>
              )}

              <li
                className={
                  filterData.status === "gst_payment_pending"
                    ? "tabs-item active"
                    : "tabs-item"
                }
                onClick={() => handleStatusClick("gst_payment_pending")}
              >
                <div>{"GST Claimed"}</div>
              </li>

              <li
                className={
                  filterData.status === "gst_paid"
                    ? "tabs-item active"
                    : "tabs-item"
                }
                onClick={() => handleStatusClick("gst_paid")}
              >
                <div>{"GST Paid"}</div>
              </li>
            </ul>
          </div>

          <div className="col-sm-4">
          {filterData.status === "calculated" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                }}
              >
                <label htmlFor="contained-button-file">
                  {export_list === 1 && (
                    <button
                      className="add-schemebtn mrg-r10"
                      onClick={() => reCalcSelectedPreEligibleLeads()}
                      disabled={disableRecalculate}
                    >
                      Re-Calculate
                    </button>
                  )}
                  
                </label>

                <label htmlFor="contained-button-file">
                  {export_list === 1 && (
                    <button
                      className="add-schemebtn"
                      onClick={() => downloadPreEligibleCsv()}
                    >
                      Export List
                    </button>
                  )}
                </label>
              </div>
            )}
            {filterData.status === "pending" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                }}
              >
                <label htmlFor="contained-button-file">
                  {export_list === 1 && (
                    <button
                      className="add-schemebtn"
                      onClick={() => downloadEligibleCsv()}
                    >
                      Export List
                    </button>
                  )}
                  <input
                    onChange={handleChange}
                    style={{ display: "none" }}
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    hidden
                  />{" "}
                </label>
              </div>
            )}
            {filterData.status === "payment_pending" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    {export_list === 1 && (
                      <button
                        className="add-schemebtn mrg-r10"
                        onClick={() => downloadCsv()}
                      >
                        Export List
                      </button>
                    )}

                    <input
                      onChange={handleChange}
                      multiple={false}
                      ref={fileInputRef}
                      type="file"
                      hidden
                      style={{ display: "none" }}
                    />
                  </div>
                  {upload_utr === 1 && (
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <button
                        className="add-schemebtn"
                        onClick={() => fileInputRef.current.click()}
                      >
                        Upload UTR
                      </button>
                      <input
                        onChange={handleChange}
                        style={{ display: "none" }}
                        multiple={false}
                        ref={fileInputRef}
                        type="file"
                        hidden
                      />
                    </div>
                  )}{" "}
                </div>
              </>
            )}

            {/* )} */}

            {filterData.status === "paid" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                }}
              >
                <label htmlFor="contained-button-file">
                  {import_gst_json === 1 && (
                    <button
                      className="add-schemebtn"
                      onClick={() => fileInputRef.current.click()}
                    >
                      Upload GST JSON
                    </button>
                  )}
                  <input
                    onChange={handleChange}
                    style={{ display: "none" }}
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    hidden
                  />{" "}
                </label>
              </div>
            )}
            {filterData.status === "gst_payment_pending" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    {export_list === 1 && (
                      <button
                        className="add-schemebtn mrg-r10"
                        onClick={() => downloadCsvGstPending()}
                      >
                        Export List
                      </button>
                    )}

                    <input
                      onChange={handleChange}
                      multiple={false}
                      ref={fileInputRef}
                      type="file"
                      hidden
                      style={{ display: "none" }}
                    />
                  </div>
                  {upload_utr === 1 && (
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <button
                        className="add-schemebtn"
                        onClick={() => fileInputRef.current.click()}
                      >
                        Upload UTR
                      </button>
                      <input
                        onChange={handleChange}
                        style={{ display: "none" }}
                        multiple={false}
                        ref={fileInputRef}
                        type="file"
                        hidden
                      />
                    </div>
                  )}{" "}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {["pending","invoice_created","payment_pending","paid"].includes(filterData.status) && <BucketTabs activeTab={activeTab} setPopup={setPopup} handleTabChange={handleTabChange} currentParentTabStatus={filterData.status}/>}
      <TableContent
        filterOptions={filterOptions}
        state={filterData}
        filterVariables={filterVariables}
        handleStatusClick={handleStatusClick}
        counter={counter}
        buttonClick={buttonClick}
        filter_params={filter_params}
        DataFIlter={DataFIlter}
        reCalculateLeadList={setReCalculateLeadList}
        productType={productType}
        financers={financers}
        activeTab={activeTab}
        openPopup={openPopup}
      />
      <AddNotesPopup openPopup={openPopup} setPopup={setPopup}/>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(SearchContent)
);
