import React, { useState } from 'react'
import DateFormate from "dateformat";
import Pagination from '../../../elements/pagination';

const StandardOtherTableContent = ({status,invoiceListData,handlePayoutListing,selectedPartnerId,invoiceData,payoutApprovalIds,selectedAmount,createInvoice,onPaginate,paginationData,filterData,financers,partnerListing,userlist}) => {
    const [selectedRow, setSelectedRow] = useState(null);

    const calculateTotalGst = (partner) => {
        const { cgst_amount, igst_amount, sgst_amount } = partner;
        return cgst_amount + igst_amount + sgst_amount;
      };
    
    const claimedTotalGst = (partner) => {
        const { cgst, igst, sgst } = partner;
        return cgst + igst + sgst;
    };

    const handleRowClick = (index) => {
        setSelectedRow(selectedRow === index ? null : index);
    };
  return (
    <div className={"clearfix"}>
              <div className="table-responsive">
                <table className="table table-bordered tablefontsize">
                  <thead>
                    <tr>
                      <th>Inv. Id</th>
                      <th>Partner Details</th>
                      <th>Payout Amt</th>
                      <th>Disb Date</th>


                      {status === "invoice_created" ||
                      status === "payment_pending" ? (
                        <th>Bank Details</th>
                      ) : status === "paid" || status === "gst_payment_pending" || status === "gst_paid" ? (
                        <th>Payment Details</th>
                      ) : null}

                       {status === "gst_payment_pending" || status === "gst_paid" ? (
                        <th>Gst Amt </th>
                     ) : null}

                       {status === "gst_paid" ? (
                        <th>GST payment details</th>
                     ) : null}

                       {status === "gst_payment_pending" ? (
                        <th>Gst Flags </th>
                     ) : null}

                      <th>Leads</th>

                      {["payment_pending","paid"].includes(status) ? (
                        <th>Invoice Status </th>
                     ) : null}

                      <th>Action</th>
                      
                      {/* <th colSpan={2}></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceListData &&
                      invoiceListData.map((partner, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                {" "}
                                {partner.id}
                              </div>
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Partner Code :{" "}
                                <span> AMK{partner.partner_id}</span>
                              </div>
                              <div style={{ opacity: 0.6 }}>
                                Partner Name :{" "}
                                <span>{partner.partner_name}</span>
                              </div>
                              {partner?.parent_partner_id>0 && <div style={{ opacity: 0.6 }}>
                              Parent Partner : <span>{partner.parent_partner_name} ({partner.parent_partner_id})</span>
                              </div>}
                              <div style={{ opacity: 0.6 }}>
                                GSTIN :{" "}
                                <span>{partner.gst_number}</span>
                              </div>
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Total Amount:{partner.payout_amount}
                              </div>
                              <div style={{ opacity: 0.6 }}>
                                PAT Amount : {partner.pat}
                              </div>
                              <div style={{ opacity: 0.6 }}>
                                TDS Aount : {partner.tds}
                              </div>
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Acknowledgment Date:&nbsp;&nbsp;&nbsp;{" "}
                                {partner.created_at
                                  ? DateFormate(
                                      new Date(partner.created_at),
                                      "dS mmm yyyy"
                                    )
                                  : null}
                              </div>
                              {partner.approved_at ? (
                                <div style={{ fontSize: 13, fontWeight: 600 }}>
                                  Approved Date:
                                  {partner.approved_at
                                    ? DateFormate(
                                        new Date(partner.approved_at),
                                        "dS mmm yyyy"
                                      )
                                    : null}
                                </div>
                              ) : null}

                              {partner.paid_date ? (
                                <div style={{ fontSize: 13, fontWeight: 600 }}>
                                  Paid Date:
                                  {partner.paid_date
                                    ? DateFormate(
                                        new Date(partner.paid_date),
                                        "dS mmm yyyy"
                                      )
                                    : null}
                                </div>
                              ) : null}
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                A/C:{" "}
                                <span style={{ opacity: 0.6 }}>
                                  {partner?.bankdetails?.bank_account_no}
                                </span>
                              </div>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                ISFC:
                                <span style={{ opacity: 0.6 }}>
                                  {partner?.bankdetails?.bank_ifsc_code}
                                </span>
                              </div>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                beneficiary Name:
                                <span style={{ opacity: 0.6 }}>
                                  {partner?.bankdetails?.beneficiary_name}
                                </span>
                              </div>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                UTR Number:
                                <span style={{ opacity: 0.6 }}>
                                  {partner?.utr_no}
                                </span>
                              </div>
                            </td>
                            { partner.paid_status === "gst_paid" ? (
                            <td>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Total Amt:
                                <span style={{ opacity: 0.6 }}>
                                {calculateTotalGst(partner)}
                                </span>
                               </div>

                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Cgst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.cgst_amount}
                                </span>
                               </div>

                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Igst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.igst_amount}
                                </span>
                               </div>

                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                sgst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.sgst_amount}
                                </span>
                               </div>
                              
                            </td>
                            ) : ""}
                             { partner.paid_status === "gst_paid" ? (
                            <td>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                               GST amount:
                                <span style={{ opacity: 0.6 }}>
                                {calculateTotalGst(partner)}
                                </span>
                               </div>

                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                               Paid date:
                                <span style={{ opacity: 0.6 }}>
                               
                                {partner.gst_paid_date
                                    ? DateFormate(
                                        new Date(partner.gst_paid_date),
                                        "dS mmm yyyy"
                                      )
                                    : null}
                                </span>
                               </div>

                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                               UTR:
                                <span style={{ opacity: 0.6 }}>
                                {partner.gst_utr_no}
                                </span>
                               </div>                              
                              
                            </td>
                            ) : ""}
                               { partner.paid_status === "gst_payment_pending" ? (
                               <td> 
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Cgst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.cgst}
                                </span>
                               </div>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Igst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.igst}
                                </span>
                               </div>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                sgst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.sgst}
                                </span>
                               </div>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                               Total Claimed:
                                <span style={{ opacity: 0.6 }}>
                                {claimedTotalGst(partner)}
                                </span>
                               </div>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                               Total Calculated:
                                <span style={{ opacity: 0.6 }}>
                                {calculateTotalGst(partner)}
                                </span>
                               </div>                              
                            </td>
                            ) : ""}

                         { partner.paid_status === "gst_payment_pending"  ? (                          
                            <td>
                              <p  style={{ color: "red" }}>{partner.is_reverse_charge && partner.is_reverse_charge >0 ? 'Reverse charges applicable' :"" }</p>
                              <p  style={{ color: "red" }}>{partner.is_itc && partner.is_itc >0 ? 'ITC not available' :"" }</p>
                              <p  style={{ color: "red" }}>{partner.is_claim_date && partner.is_claim_date >0 ? 'Claim date incorrect' :"" }</p>
                              <p  style={{ color: "red" }}>{partner.is_gst_calc && partner.is_gst_calc >0 ? 'GST calculations mismatch' :"" }</p>
                            </td>
                            ) : ""}



                            <td
                              onClick={() =>
                                handlePayoutListing(index, partner.id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: 13,
                                  fontWeight: 600,
                                }}
                              >
                                {partner.total_lead}
                              </div>
                              <i
                                className="ic-keyboard_arrow_down"
                                style={{ fontSize: 24, marginLeft: -8 }}
                              ></i>
                              {/* <img
                            src={require("./../../ruleEngine/assets/images/downarrow.svg")}
                            alt=""
                            onClick={() =>
                              handlePayoutListing(index, partner.id)
                            }
                            style={{ cursor: "pointer" }}
                          /> */}
                            </td>
                            { ["payment_pending","paid"].includes(partner.paid_status)  ? (                          
                            <td>
                              <p  style={{ color: partner.invoice_approval==1?"green":"red" }}>{partner.invoice_approval==1?"Approved":"Approval Pending"}</p>
                            </td>
                            ) : ""}

                            { //Status === "gst_payment_pending" ? null : (
                              <>
                                <td>
                                  <div style={{ display: "flex" }}>
                                    <div className="btn-group btn-group-small mrg-r10">
                                      <div
                                        className="btn btn-default"
                                        title="Mark as View"
                                        onClick={() =>
                                          handlePayoutListing(index, partner.id)
                                        }
                                      >
                                        <i className="ic-remove_red_eyevisibility icons-normal"></i>
                                      </div>
                                    </div>
                                    <div>
                                      <a
                                        target="_blank"
                                        href={
                                          partner.paid_status ==
                                          "payment_pending"
                                            ? partner.pending_invoice_url
                                            : partner.approved_invoice_url
                                        }
                                      >
                                        <div className="btn-group btn-group-small mrg-r10">
                                          <div
                                            className="btn btn-default"
                                            title="Mark as View"
                                          >
                                            <i
                                              className="ic-pdf icons-normal"
                                              style={{
                                                fontSize: 16,
                                                color: "gray",
                                              }}
                                            ></i>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </>
                           // )
                           }
                          </tr>

                          {selectedPartnerId == partner.id ? (
                            <tr
                              style={{
                                backgroundColor: "rgb(245,245,245)",
                                paddingRight: "40px",
                                paddingLeft: "40px",
                              }}
                            >
                              <td colSpan={8}>
                                <div className="table-responsive">
                                  <table className="table table-bordered tablefontsize">
                                    <thead>
                                      <tr>
                                        <th className="payouttable">
                                          {"Lead Details"}
                                        </th>
                                        <th className="payouttable">
                                          {"Scheme Details"}
                                        </th>
                                        <th className="payouttable">
                                          {"Calc. Amount"}
                                        </th>
                                        <th className="payouttable">
                                          {"Bank"}
                                        </th>
                                        <th className="payouttable">
                                          {"Calc. Date"}
                                        </th>
                                        <th className="payouttable">
                                          {"Payout%"}
                                        </th>
                                        <th className="payouttable">
                                          {"Payout Details"}
                                        </th>
                                        <th className="payouttable">{"PAT"}</th>
                                        <th className="payouttable">{"Force Approved"}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {invoiceData &&
                                        invoiceData.map(
                                          (partnerlead, index) => (
                                            <React.Fragment key={index}>
                                              <tr
                                                onClick={() =>
                                                  handleRowClick(index)
                                                }
                                              >
                                                <td>
                                                  <div>
                                                    ID :{" "}
                                                    <span>
                                                      {
                                                        partnerlead?.payouts
                                                          ?.partnerleads
                                                          ?.lead_id
                                                      }
                                                    </span>
                                                  </div>
                                                  <div>
                                                    Tranche :{" "}
                                                    <span>
                                                      {
                                                        partnerlead?.payouts
                                                          ?.partnerleads
                                                          ?.tranche_id
                                                      }
                                                    </span>
                                                  </div>
                                                  <div>
                                                    App:{" "}
                                                    <span>
                                                      {
                                                        partnerlead?.payouts
                                                          ?.partnerleads?.app_id
                                                      }
                                                    </span>
                                                  </div>
                                                </td>

                                                <td>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    Partner Code:
                                                    <span>
                                                      {" "}
                                                      AMK{partner.partner_id}
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    Partner Name:
                                                    {
                                                      partnerlead?.payouts
                                                        ?.scheme?.scheme_name
                                                    }
                                                  </div>
                                                </td>
                                                <td>
                                                  { partnerlead?.payouts?.partnerleads?.slab_amount =="sanction_amount" ? partnerlead?.payouts
                                                      ?.partnerleads
                                                      ?.sanction_amount :
                                                    partnerlead?.payouts
                                                      ?.partnerleads
                                                      ?.disbursal_amount
                                                  }
                                                  <div class="tooltip">
                                                  
                                                          <div style={{ display: "flex" }} class="btn btn-default" ><i className="ic-info-icon1 icons-normal"></i></div>
                                                          <div class="tooltiptext">
                                                            {partnerlead?.payouts?.partnerleads?.slab_date ? <div>Slab Date : {partnerlead?.payouts?.partnerleads?.slab_date.replace("_", " ")}</div>:""}
                                                            {partnerlead?.payouts?.partnerleads?.slab_amount ? <div>Slab Amount : {partnerlead?.payouts?.partnerleads?.slab_amount.replace("_", " ")}</div>:""}
                                                            </div>
                                                        </div>
                                                </td>
                                                <td>
                                                  {financers &&
                                                    financers.length > 0 &&
                                                    partnerlead.id &&
                                                    financers.filter(
                                                      (bank) =>
                                                        bank.id ==
                                                        partnerlead.payouts
                                                          .partnerleads
                                                          .financer_id
                                                    )[0]?.name}
                                                </td>
                                                <td>
                                                  {DateFormate(
                                                    new Date(
                                                      partnerlead.payouts.partnerleads.disbursal_date
                                                    ),
                                                    "dS mmm yyyy"
                                                  )}
                                                </td>
                                                <td>
                                                  <div>
                                                    {" "}
                                                    Payout:{" "}
                                                    {
                                                      partnerlead?.payouts
                                                        ?.payout_per
                                                    }
                                                    %
                                                  </div>
                                                  <div>
                                                    {" "}
                                                    Paid:{" "}
                                                    {
                                                      partnerlead?.payouts
                                                        ?.paid_per
                                                    }
                                                    %
                                                  </div>
                                                </td>
                                                <td>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    Payout:
                                                    {partnerlead?.payout_amount}
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    PAT amount:
                                                    {partnerlead?.pat}
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    TDS amount:
                                                    {partnerlead?.tds}
                                                  </div>
                                                </td>
                                                <td>
                                                  {partnerlead?.pat ??
                                                    0 -
                                                      (partnerlead?.pat ?? 0) -
                                                      (partnerlead?.tds ?? 0)}
                                                </td>
                                                <td>
                                                  {partnerlead.payouts.force_approved==1?<div style={{display:"flex",alignItems:"center",gap:"5px"}}>
                                                    <span  className="force-approve-yes" >Yes</span>
                                                    <div class="tooltip">
                                                      <div style={{ display: "flex" }} class="btn btn-default" ><i className="ic-info-icon1 icons-normal"></i></div>
                                                      <div class="tooltiptext">
                                                        <div>Comment : {partnerlead.payouts.comment}</div>
                                                        {partnerlead.payouts.created_by && userlist.length > 0 ?<div>Created By : {userlist.find((user) => user.value == partnerlead.payouts.created_by)?.label||null}</div>:""}
                                                        {partnerlead.payouts.updated_by && userlist.length > 0 ?<div>Updated By : {userlist.find((user) => user.value == partnerlead.payouts.updated_by)?.label||null}</div>:""}
                                                        {partnerlead.payouts?.updated_at && <div>Updated At : {DateFormate(
                                                          new Date(
                                                            partnerlead.payouts.updated_at
                                                          ),
                                                          "dS mmm yyyy"
                                                        )}</div>}
                                                          </div>
                                                      </div>

                                                  </div>:"No"}
                                                  </td>
                                              </tr>
                                            </React.Fragment>
                                          )
                                        )}
                                    </tbody>
                                    {payoutApprovalIds &&
                                    payoutApprovalIds.length > 0 ? (
                                      <tr>
                                        <td colSpan={8}>Total</td>
                                        <td colSpan={2}>{selectedAmount}</td>
                                        <td colSpan={2}>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                              createInvoice(partner.partner_id)
                                            }
                                            title="View History"
                                          >
                                            Create Invoice
                                          </button>
                                        </td>
                                      </tr>
                                    ) : null}
                                  </table>
                                </div>
                              </td>
                            </tr>
                          ) : null}
                        </React.Fragment>
                      ))}
                    {partnerListing.length === 0 && (
                      <tr>
                        <td colSpan={3}>No payout found!</td>
                      </tr>
                    )}
                    {partnerListing.length !== 0 ?
                    <tr>
                      <td colSpan={3}>
                        {" "}
                        <Pagination
                          pageCount={
                            paginationData && paginationData.totalpage
                              ? paginationData.totalpage
                              : 0
                          }
                          activePage={
                            filterData.page_no ? filterData.page_no : 1
                          }
                          onPaginate={onPaginate}
                        />
                      </td>
                      <td>
                        Total:{" "}
                        {paginationData && paginationData.totalrecords
                          ? paginationData.totalrecords
                          : 0}{" "}
                      </td>
                    </tr> :null }
                  </tbody>
                </table>
              </div>
            </div>
  )
}

export default StandardOtherTableContent
